






import AuthTypes from '@/store/types/AuthTypes'
import ForecastTypes from '@/store/types/ForecastTypes'
import UtilsTypes from '@/store/types/UtilsTypes'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import db from '@/firebase'
import WalletTypes from '@/store/types/WalletTypes'

@Component
export default class Main extends Vue {
  created () { this.prepareData() }

  async prepareData () {
    if (this.isOutsideHome() || this.utilState.cronsRunning) return
    this.loading(true)
    await Promise.all([this.setFavorites(), this.setMarkets(), this.setTickers()]).catch((error: any) => this.$toasted.global.error(error))
    await this.updateCronsRunning(true)
    this.loading(false)
  }

  get showTemplate () {
    if (this.isOutsideHome()) {
      this.updateCronsRunning(false)
      return true
    }
    if (Object.keys(this.state.tickersFormatted).length) return true
    return false
  }

  getNotifications () {
    db.collection('notifications').where('show', '==', false).where('user_id', '==', this.stateUser.id).onSnapshot(ref => {
      ref.docChanges().forEach(change => {
        const { doc, type } = change
        const data = doc.data()
        db.collection('notifications').doc(change.doc.id).update({ show: true })
        if (type === 'added') {
          switch (data.type) {
            case 'trade_close':
              return this.$toasted.global.success({ message: data.body })
            default:
              return this.$toasted.global.success({ message: data.body })
          }
        }
      })
    })
  }

  isOutsideHome () {
    if (this.$route.name) {
      return this.$route.name.toLowerCase().includes('signup') ||
        this.$route.name.toLowerCase().includes('login') ||
        this.$route.name === 'TradingNights'
    }
    return false
  }

  @State('ForecastModule') state: any;
  @State('UtilsModule') utilState: any;
  @State('UserModule') stateUser: any;
  @Getter(`AuthModule/${AuthTypes.getters.GET_CURRENT_USER}`) user: any;
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Action(`ForecastModule/${ForecastTypes.actions.UPDATE_DATA_TICKERS}`) setTickers: any
  @Action(`ForecastModule/${ForecastTypes.actions.UPDATE_DATA_MARKETS}`) setMarkets: any
  @Action(`ForecastModule/${ForecastTypes.actions.UPDATE_DATA_FAVORITES}`) setFavorites: any
  @Action(`UtilsModule/${UtilsTypes.actions.CRONS_RUNNING}`) updateCronsRunning: any;
  @Action(`WalletModule/${WalletTypes.actions.SET_BALANCE_BINANCE}`) setBinanceBalance: any;
}
