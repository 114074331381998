






























































































import { Component, Vue } from 'vue-property-decorator'
import { Getter, State, Action } from 'vuex-class'
import NavigationTypes from '@/store/types/NavigationTypes'
import InvestmentService from '@/services/investments'
import numeral from 'numeral'
import moment from 'moment'

@Component({
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  },
  components: {
    SimpleSymbolSelect: () => import('@/components/dashboard/Forecast/SimpleSymbolSelect.vue')
  }
})
export default class Baskets extends Vue {
  currency: any
  search = ''
  userInvestments: any = []
  moment = moment

  mounted () {
    this.setBreadcrums([
      { text: 'lateralMenu.home', to: { name: 'Home' } },
      { text: 'lateralMenu.my-investments', to: { name: 'MyInvestments' } },
      { text: 'lateralMenu.my-baskets', active: true }
    ])
    this.getMyInvestments()
  }

  async getMyInvestments () {
    this.loading(true)
    const [error, { data: userInvestments }] = await InvestmentService.getUserInvestments()
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.userInvestments = userInvestments.map((el: any) => ({ ...el, show: true }))
  }

  cleanFilters () {
    this.userInvestments = this.userInvestments.map((el: any) => ({ ...el, show: true }))
  }

  applyBasketsFilters (filters: any) {
    this.userInvestments = this.userInvestments.map((el: any) => ({ ...el, show: true }))

    const symbolsFiltered = filters.symbol_filter.map((el: any) => el.symbol)
    const riskFiltered = filters.risk_filter.map((el: any) => el.risk_id)

    if (symbolsFiltered.length > 0 && riskFiltered.length > 0) {
      this.userInvestments = this.userInvestments.map((el: any) => {
        for (const symbol of symbolsFiltered) {
          if (el.current_allocation.labels.includes(symbol) && riskFiltered.includes(el.basket_risk)) {
            return { ...el, show: true }
          } else {
            return { ...el, show: false }
          }
        }
      })
    } else if (symbolsFiltered.length > 0) {
      this.userInvestments = this.userInvestments.map((el: any) => {
        for (const symbol of symbolsFiltered) {
          if (el.current_allocation.labels.includes(symbol)) {
            return { ...el, show: true }
          } else {
            return { ...el, show: false }
          }
        }
      })
    } else if (riskFiltered.length > 0) {
      this.userInvestments = this.userInvestments.map((el: any) => {
        for (const risk of riskFiltered) {
          if (riskFiltered.includes(el.basket_risk)) {
            return { ...el, show: true }
          } else {
            return { ...el, show: false }
          }
        }
      })
    }
  }

  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @State('ForecastModule') state: any;
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
}
