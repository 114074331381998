import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State from '@/store/interfaces/Utils'
import UtilsTypes from '@/store/types/UtilsTypes'

const namespaced = true

const stepPresentation = localStorage.getItem('stepPresentation') ? Number(localStorage.getItem('stepPresentation')) : 1
const modeSandbox = false
const currentStepOnboarding = localStorage.getItem('toursData') ? JSON.parse(localStorage.getItem('toursData') as string) : {
  menu: { step: 0, completed: false },
  home: { step: 0, completed: false },
  coins: { step: 0, completed: false },
  marketAnalysis: { step: 0, completed: false },
  invest: { step: 0, completed: false },
  investDetail: { step: 0, completed: false },
  learn: { step: 0, completed: false },
  myInvestments: { step: 0, completed: false },
  tradeIdeas: { step: 0, completed: false }
}

const state: State = {
  cronsRunning: false,
  showModalTrade: false,
  showModalError500: false,
  showGlobalSearch: false,
  currentStepOnboarding,
  stepPresentation,
  modeSandbox,
  username: localStorage.getItem('username') || '',
  avatarSelected: localStorage.getItem('avatarSelected') || 'green'
}

const getters: GetterTree<State, any> = {
  [UtilsTypes.getters.GET_DATA]: (state) => state
}

const mutations: MutationTree<State> = {
  [UtilsTypes.mutations.CRONS_RUNNING]: async (stateMut, payload: boolean) => {
    state.cronsRunning = payload
  },
  [UtilsTypes.mutations.SHOW_MODAL_TRADES]: async (stateMut, payload: boolean) => {
    state.showModalTrade = payload
  },
  [UtilsTypes.mutations.SHOW_MODAL_ERROR_500]: async (stateMut, payload: boolean) => {
    state.showModalError500 = payload
  },
  [UtilsTypes.mutations.SHOW_GLOBAL_SEARCH]: async (stateMut, payload: boolean) => {
    state.showGlobalSearch = payload
  },
  [UtilsTypes.actions.STEP_PRESENTATION]: async (stateMut, payload: number) => {
    state.stepPresentation = payload
    localStorage.setItem('stepPresentation', payload.toString())
  },
  [UtilsTypes.actions.MODE_SANDBOX]: async (stateMut, payload: boolean) => {
    state.modeSandbox = payload
    localStorage.setItem('modeSandbox', String(payload))
  },
  [UtilsTypes.actions.AVATAR_SELECTED]: async (stateMut, payload: string) => {
    state.avatarSelected = payload
    localStorage.setItem('avatarSelected', payload)
  },
  [UtilsTypes.actions.USERNAME_SELECTED]: async (stateMut, payload: string) => {
    state.username = payload
    localStorage.setItem('username', payload)
  },
  [UtilsTypes.actions.CHANGE_STEP_ONBOARDING_TOUR]: async (stateMut, payload: { key: string; step: number; completed: boolean; }) => {
    state.currentStepOnboarding[payload.key].step = payload.step
    state.currentStepOnboarding[payload.key].completed = payload.completed
    localStorage.setItem('toursData', JSON.stringify(state.currentStepOnboarding))
  },
  [UtilsTypes.actions.CLEAR_DATA]: async () => {
    state.cronsRunning = false
    state.showModalTrade = false
    state.showModalError500 = false
    state.username = ''
    state.avatarSelected = 'green'
  }
}

const actions: ActionTree<State, any> = {
  [UtilsTypes.actions.CRONS_RUNNING]: async ({ commit }, payload: boolean) => {
    commit(UtilsTypes.mutations.CRONS_RUNNING, payload)
    return [null]
  },
  [UtilsTypes.actions.SHOW_MODAL_TRADES]: async ({ commit }, payload: boolean) => {
    commit(UtilsTypes.mutations.SHOW_MODAL_TRADES, payload)
    return [null]
  },
  [UtilsTypes.actions.SHOW_MODAL_ERROR_500]: async ({ commit }, payload: boolean) => {
    commit(UtilsTypes.mutations.SHOW_MODAL_ERROR_500, payload)
    return [null]
  },
  [UtilsTypes.actions.SHOW_GLOBAL_SEARCH]: async ({ commit }, payload: boolean) => {
    commit(UtilsTypes.mutations.SHOW_GLOBAL_SEARCH, payload)
    return [null]
  },
  [UtilsTypes.actions.STEP_PRESENTATION]: async ({ commit }, payload: number) => {
    commit(UtilsTypes.mutations.STEP_PRESENTATION, payload)
    return [null]
  },
  [UtilsTypes.actions.MODE_SANDBOX]: async ({ commit }, payload: boolean) => {
    commit(UtilsTypes.mutations.MODE_SANDBOX, payload)
    return [null]
  },
  [UtilsTypes.actions.AVATAR_SELECTED]: async ({ commit }, payload: string) => {
    commit(UtilsTypes.mutations.AVATAR_SELECTED, payload)
    return [null]
  },
  [UtilsTypes.actions.USERNAME_SELECTED]: async ({ commit }, payload: string) => {
    commit(UtilsTypes.mutations.USERNAME_SELECTED, payload)
    return [null]
  },
  [UtilsTypes.actions.CHANGE_STEP_ONBOARDING_TOUR]: async ({ commit }, payload: { key: string; step: number; completed: boolean; }) => {
    commit(UtilsTypes.mutations.CHANGE_STEP_ONBOARDING_TOUR, payload)
    return [null]
  },
  [UtilsTypes.actions.CLEAR_DATA]: async ({ commit }) => {
    commit(UtilsTypes.mutations.CLEAR_DATA)
    return [null]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
