





















import { Component, Vue, Watch } from 'vue-property-decorator'
import Error500 from '@/components/errors/500.vue'
import { Action, Getter, State } from 'vuex-class'
import LocationTypes from '@/store/types/LocationTypes'
import LocationState from '@/store/interfaces/Location'
import EventBus from './event-bus'
import Utils from './helpers/Utils'

@Component({
  components: {
    Error500,
    LottieAnimation: () => import('lottie-vuejs/src/LottieAnimation.vue'),
    'global-search': () => import('@/components/dashboard/GlobalSearch.vue'),
    Wallets: () => import('@/components/dashboard/Wallets.vue'),
    ChangeLangByUbication: () => import('@/components/ChangeLangByUbication.vue')
  }
})
export default class App extends Vue {
  showUpdateUI = false
  showModalChangeLang = false

  @Watch('$store.state.theme', { immediate: true })
  changeTheme () {
    const element = document.getElementById('body-content')
    if (element) element.className = `theme-${this.$store.state.theme}`
  }

  mounted () {
    // @ts-ignore
    if (this.$workbox) this.$workbox.addEventListener('waiting', () => { this.showUpdateUI = true })
    this.prepareChangeLanguageByUbication()
  }

  async prepareChangeLanguageByUbication () {
    const questionChangeLang = localStorage.getItem('question-change-lang')
    await this.setCountries()
    await this.setLocation()
    if (questionChangeLang) return
    if (this.validateLocationLang(this.locationData.location.languages.iso639_1)) {
      if (this.$i18n.locale !== this.locationData.location.languages.iso639_1) {
        this.showModalChangeLang = true
        await Utils.sleep(50)
        EventBus.$emit('open-modal-change-lang-ubication')
      } else localStorage.setItem('question-change-lang', 'no')
      return
    }
    this.$i18n.locale = 'en'
    localStorage.setItem('lang', 'en')
    localStorage.setItem('question-change-lang', 'no')
  }

  validateLocationLang (currentLang: string) {
    const langs = ['es', 'en']
    return langs.find(lang => lang === currentLang)
  }

  async accept () {
    this.showUpdateUI = false
    // @ts-ignore
    await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
  }

  @State('ForecastModule') stateForecast: any;
  @State('UtilsModule') state: any;
  @State('UserModule') user: any;
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter(`LocationModule/${LocationTypes.getters.GET_DATA}`) locationData!: LocationState;
  @Action(`LocationModule/${LocationTypes.actions.SET_COUNTRIES}`) setCountries: any;
  @Action(`LocationModule/${LocationTypes.actions.SET_LOCATION}`) setLocation: any;
}
