import Vue from 'vue'
// PLUGINS
import './plugins/bootstrap-vue'
import './plugins/vue-validate'
import './plugins/vue-toasted'
import './plugins/vue-clipboard'
import './plugins/load-script'
import './plugins/vue-confetti'
import './plugins/one-signal-vue'
import './plugins/vue-ellipse-progress'
import './plugins/vuefire'
import './plugins/vue-tour'
// INIT
import App from './App.vue'
import wb from './registerServiceWorker'
// UTILS
import router from './router'
import store from './store'
import i18n from './i18n'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

Vue.component('VueSlider', VueSlider)
Vue.config.productionTip = false
Vue.prototype.$workbox = wb

const element = document.getElementById('body-content')
if (element) element.className = `theme-${store.state.theme}`

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
