import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'

const routes: Array<RouteConfig> = [
  {
    path: '/signup',
    component: () => import(/* webpackChunkName: "IndexSignup" */'@/views/auth/Index.vue'),
    children: [
      {
        path: '',
        name: 'Signup',
        component: () => import(/* webpackChunkName: "Login" */'@/views/auth/Signup.vue'),
        beforeEnter: multiguard([])
      },
      {
        path: '/recover',
        name: 'Recover',
        component: () => import(/* webpackChunkName: "Recover" */'@/views/auth/Recover.vue'),
        beforeEnter: multiguard([])
      }
    ]
  }
]

export default routes
