import to from 'await-to-js'
import axios from '@/utils/axios-api'
import { CreateInvestment } from '@/interfaces/Investment'
import db from '@/firebase'

const API_URL = process.env.VUE_APP_NEW_WRABBIT_API

export default class InvestmentService {
  static async getUserTransactions () {
    return await to(axios.get(`${API_URL}/user/transactions`)) as any
  }

  static async getUserPortfolioPerformance (clock: string) {
    return await to(axios.get(`${API_URL}/user/portfolio_performance/${clock}`)) as any
  }

  static async getUserPortfolioAllocations () {
    return await to(axios.get(`${API_URL}/user/portfolio_allocations`)) as any
  }

  static async getUserInvestments () {
    return await to(axios.get(`${API_URL}/user/investments`)) as any
  }

  static async createInvestment (data: CreateInvestment) {
    return await to(axios.post(`${API_URL}/investment/create`, data)) as any
  }

  static async simulateInvest (doc: string) {
    const row: any = await db.collection('portfolio_simulation_charts').doc(doc).get()
    if (row.exists) return [null, row.data()]
    return [{ message: 'Not found' }]
  }
}
