















import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component
export default class Error500 extends Vue {
  reload () { window.location.reload() }

  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @State('UtilsModule') state: any;
}
