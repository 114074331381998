import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: 'trading-nights',
    component: () => import('@/components/Main.vue'),
    children: [
      {
        path: '',
        name: 'TradingNightsPro',
        component: () => import('@/views/dashboard/trading-nights/Index.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.isMentor, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Trading Nights' }
      },
      {
        path: 'user',
        name: 'TradingNightsUser',
        component: () => import('@/views/dashboard/trading-nights/User.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.isWrabbitAlpha, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Trading Nights | User' }
      },
      {
        path: 'user/live',
        name: 'TradingNightsLive',
        component: () => import('@/views/dashboard/trading-nights/Live.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.isWrabbitAlpha, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Trading Nights | Live' }
      },
      {
        path: 'user/live/meeting',
        name: 'TradingNightsLiveMeeting',
        component: () => import('@/views/dashboard/trading-nights/EnjoyMeeting.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.isWrabbitAlpha]),
        meta: { title: 'Trading Nights | Meeting' }
      }
    ]
  }
]
export default routes
