import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: 'learn',
    component: () => import('@/components/Main.vue'),
    children: [
      {
        path: '',
        name: 'Learn',
        component: () => import('@/views/dashboard/learn/Index.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Learn' }
      },
      {
        path: ':id/detail',
        name: 'LearnDetail',
        component: () => import('@/views/dashboard/learn/Detail.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Learn' }
      }
    ]
  }
]
export default routes
