import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Forecast from '@/views/dashboard/Forecast.vue'
import MarketGroup from '@/views/dashboard/markets/MarketGroup.vue'
import MarketCustomFilters from '@/views/dashboard/markets/MarketCustomFilters.vue'
import Auth from '@/middlewares/Auth'
import Main from '@/components/Main.vue'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: 'markets',
    component: Main,
    name: 'Markets',
    redirect: 'markets/overview',
    children: [
      {
        path: 'custom-filter',
        component: Main,
        children: [
          {
            path: '',
            name: 'MarketCustomFilters',
            component: MarketCustomFilters,
            beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed])
          },
          {
            path: ':filter/group',
            name: 'MarketGroup',
            component: MarketGroup,
            beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed])
          },
          {
            path: ':filter/group/forecast/:symbol',
            name: 'MarketGroupForecast',
            component: Forecast,
            beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed])
          }
        ]
      }
    ]
  }
]
export default routes
