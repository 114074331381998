import to from 'await-to-js'
import axios from '@/utils/axios-api'
import db from '@/firebase'

const STORYTELLING_API_URL = process.env.VUE_APP_MARKET_JOB_API

export default class QuickScanService {
  static async index () {
    return await to(axios.get(`${STORYTELLING_API_URL}/forecast_analysis_symbols`)) as any
  }

  static async find (symbol: string) {
    return await to(axios.get(`${STORYTELLING_API_URL}/forecast_analysis/${symbol}`)) as any
  }

  static async marketOverview () {
    return await to(axios.get(`${STORYTELLING_API_URL}/market_analysis/summary`)) as any
  }

  static async communityVote (data: { category: string, price_up: number, comment: string }) {
    return await to(axios.post(`${STORYTELLING_API_URL}/community_vote`, data)) as any
  }

  static async marketOverviewByCategory (category: string) {
    return await to(axios.get(`${STORYTELLING_API_URL}/market_analysis/filter/${category}`)) as any
  }

  static async marketVolatility (category = 'BTCUSDT') {
    const chart = await db.collection('trend_chart').doc(category).get()
    if (chart.exists) {
      const data: any = chart.data()
      return [null, JSON.parse(data.data)]
    }
    return [{ message: 'Not found' }]
  }

  static async getMarketCap () {
    const row: any = await db.collection('mktcap_stats').doc('info').get()
    if (row.exists) {
      const data: any = row.data()
      return [null, JSON.parse(data.data)]
    }
    return [{ message: 'Not found' }]
  }
}
