import to from 'await-to-js'
import axios from '@/utils/axios-api'
import Login from '@/interfaces/Login'
import GoogleLogin from '@/interfaces/GoogleLogin'
import Config from '@/config'

const API = Config.api

export default class User {
  static async login (data: Login) {
    return await to(axios.post(`${API}/user/login`, { ...data, register_source: 'web_version' })) as any
  }

  static async googleLogin (googleUser: GoogleLogin) {
    return await to(axios.post(`${API}/google_login`, {
      sub: googleUser.sub,
      email: googleUser.email,
      given_name: googleUser.givenName,
      referred_by: googleUser.referred_by,
      register_source: 'web_version',
      avatar_color: googleUser.avatar_color
    }))
  }

  static async refreshToken () {
    return await to(axios.post(`${API}/user/refresh`)) as any
  }

  static async confirmAccount (email: string, otp: number) {
    return await to(axios.post(`${API}/confirmation/validate/${email}`, { otp })) as any
  }

  static async resendEmailConfirmation (email: string) {
    return await to(axios.post(`${API}/resend_confirmation_email/${email}`)) as any
  }

  static async recoverPassword (email: string) {
    return await to(axios.post(`${API}/user/recover`, { email })) as any
  }
}
