export default {
  mutations: {
    SET_PAYLOAD: 'SET_TOKEN',
    REMOVE_TOKEN: 'REMOVE_TOKEN'
  },
  getters: {
    GET_PAYLOAD: 'GET_PAYLOAD',
    GET_CURRENT_USER: 'GET_CURRENT_USER'
  },
  actions: {
    REGISTER: 'REGISTER',
    LOGOUT: 'LOGOUT',
    LOGIN: 'LOGIN',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    GOOGLE_LOGIN: 'GOOGLE_LOGIN'
  }
}
