import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State, { CurrentSymbolSelect } from '@/store/interfaces/Symbol'
import SymbolTypes from '@/store/types/SymbolTypes'

const namespaced = true

const keyLocalStorage = 'currentSymbolSelected'

const payloadLocalStorage: CurrentSymbolSelect = JSON.parse(localStorage.getItem(keyLocalStorage) || JSON.stringify({
  logo_url: '',
  major: '',
  symbol: '',
  minor: ''
}))

const state: State = {
  current_symbol_selected: payloadLocalStorage
}

const getters: GetterTree<State, any> = {
  [SymbolTypes.getters.GET_DATA]: (state) => state
}

const mutations: MutationTree<State> = {
  [SymbolTypes.mutations.UPDATE_CURRENT_SELECTED_SYMBOL]: async (stateMut, payload: CurrentSymbolSelect) => {
    state.current_symbol_selected = payload
    localStorage.setItem(keyLocalStorage, JSON.stringify(state.current_symbol_selected))
  }
}

const actions: ActionTree<State, any> = {
  [SymbolTypes.actions.UPDATE_CURRENT_SELECTED_SYMBOL]: async ({ commit }, payload: CurrentSymbolSelect) => {
    commit(SymbolTypes.mutations.UPDATE_CURRENT_SELECTED_SYMBOL, payload)
    return [null]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
