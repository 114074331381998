import { required, email, minLength, helpers } from 'vuelidate/lib/validators'

const validations = {
  information: {
    username: { required }
  },
  withFriends: {
    emails: {
      required,
      minLength: minLength(1),
      $each: {
        required,
        email
        // required
      }
    }
  }
}

export default validations
