import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Auth from '@/middlewares/Auth'
import Main from '@/components/Main.vue'
import User from '@/middlewares/User'
import Forecast from '@/views/dashboard/Forecast.vue'

const routes: Array<RouteConfig> = [
  {
    path: 'ask-wrabbit',
    component: Main,
    beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
    children: [
      {
        path: 'quick-scan/forecast/:symbol',
        name: 'QuickScanForecast',
        component: Forecast,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed])
      }
    ]
  }
]

export default routes
