import to from 'await-to-js'
import axios from '@/utils/axios-api'
import { CreateOrder } from '@/interfaces/Plan'

const PANEL_URL = process.env.VUE_APP_API

export default class PlanService {
  static async getPaymentLinks () {
    return await to(axios.get(`${PANEL_URL}/payment_links`)) as any
  }

  static async getPaymentMethods () {
    return await to(axios.get(`${PANEL_URL}/payment_methods/crypto/all`)) as any
  }

  // if null create order
  static async generateInvoice (product: string, paymentMethod: string, currency: string) {
    return await to(axios.get(`${PANEL_URL}/order/crypto_invoice`, { params: { product, payment_method: paymentMethod, currency } })) as any
  }

  static async createOrder (data: CreateOrder) {
    return await to(axios.post(`${PANEL_URL}/order`, data)) as any
  }

  static async createOrderCreditCard (data: { product: 'Crypto Addict Monthly' | 'Crypto Addict Yearly' }) {
    return await to(axios.post(`${PANEL_URL}/order_stripe`, data)) as any
  }

  static async redirectToStripe (product: 'Crypto Addict Monthly' | 'Crypto Addict Yearly', coupon: any = null) {
    return await to(axios.post(`${PANEL_URL}/stripe_checkout`, { product, coupon })) as any
  }

  static async validateCodePreOffer (data: { email: string; code: string }) {
    return await to(axios.post(`${PANEL_URL}/subscription/validate`, data)) as any
  }
}
