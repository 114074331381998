import store from '@/store'
import StateUser from '@/store/interfaces/User'
import UserTypes from '@/store/types/UserTypes'

export default class TrackEvents {
  static async track (event: string, additional?: any) {
    if (process.env.NODE_ENV === 'production') {
      const user = store.getters[`UserModule/${UserTypes.getters.GET_PAYLOAD}`] as StateUser
      await window.analytics.identify(user.id, { email: user.email })
      await window.analytics.track(event, {
        user_id: user.id,
        user_email: user.email,
        ...additional
      })
    }
  }
}
