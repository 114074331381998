

































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import HeaderCustom from '@/components/Header.vue'
import VueSlideBar from 'vue-slide-bar'
import numeral from 'numeral'
import QuickScanService from '@/services/quick-scan'
import Plotly from 'plotly.js'
import Utils from '@/helpers/Utils'

@Component({
  components: {
    HeaderCustom: () => import('@/components/Header.vue'),
    VueSlideBar: () => import('vue-slide-bar')
  },
  filters: {
    currency (value: number) { return numeral(value).format('0,0') }
  }
})
export default class QuickScanPresentation extends Vue {
  symbol = ''
  comments = ''
  step = 1
  lastStep = 6
  response = ''
  data: any = {
    slide_1: { en: '', es: '', plot: { data: [], layout: {} } },
    slide_2: { en: '', es: '', plot: { data: [], layout: {} } },
    slide_3: { en: '', es: '', plot: { data: [], layout: {} } },
    slide_4: { en: '', es: '', plot: { data: [], layout: {} } },
    slide_5: { '1d': '', '1h': '', '30m': '', '4h': '' }
  }

  @Watch('step')
  async changeStep () {
    if (this.step < 5) {
      await Utils.sleep(10)
      this.data['slide_' + this.step].plot.layout.height = 400
      delete this.data['slide_' + this.step].plot.layout.width
      delete this.data['slide_' + this.step].plot.data[0].hovertemplate
      this.data['slide_' + this.step].plot.layout.font.family = 'Quicksand'
      this.data['slide_' + this.step].plot.layout.margin = { t: 0, l: 0, r: 0, b: 0 }
      Plotly.newPlot('slide_' + this.step, this.data['slide_' + this.step].plot.data, this.data['slide_' + this.step].plot.layout, { responsive: true, displayModeBar: false })
    }
  }

  get validateForm () {
    return this.response !== '' && this.comments !== ''
  }

  mounted () {
    this.symbol = this.$route.params.symbol.replaceAll('_', '/')
    this.getData()
    document.addEventListener('keydown', this.createEvent, true)
  }

  createEvent (event: any) {
    event.stopPropagation()
    if (this.$route.name !== 'QuickScanPresentation') {
      this.removeEvent()
      return
    }
    if (event.code === 'ArrowRight' || event.code === 'ArrowLeft') this.movePresentation(event.code)
  }

  removeEvent () { document.removeEventListener('keydown', this.createEvent, true) }

  async getData () {
    this.loading(true)
    const [error, response] = await QuickScanService.find(this.$route.params.symbol.replaceAll('_', ''))
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.data = response.data
    this.data['slide_' + this.step].plot.layout.height = 400
    delete this.data['slide_' + this.step].plot.layout.width
    delete this.data['slide_' + this.step].plot.data[0].hovertemplate
    this.data['slide_' + this.step].plot.layout.font.family = 'Quicksand'
    this.data['slide_' + this.step].plot.layout.margin = { t: 35, l: 0, r: 0, b: 0 }
    Plotly.newPlot('slide_' + this.step, this.data['slide_' + this.step].plot.data, this.data['slide_' + this.step].plot.layout, { responsive: true, displayModeBar: false })
  }

  movePresentation (code: 'ArrowRight'|'ArrowLeft') {
    if (code === 'ArrowRight') {
      if (this.step >= this.lastStep) return
      this.step++
    } else if (code === 'ArrowLeft') {
      if (this.step === 1) {
        this.removeEvent()
        this.$router.back()
      }
      if (this.step <= 1) return
      this.step--
    }
  }

  async sendComments () {
    this.loading(true)
    const [error] = await QuickScanService.communityVote({ category: 'market', price_up: this.response === 'up' ? 1 : 0, comment: this.comments })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.$toasted.global.success({ message: 'Gracias por tus comentarios' })
    this.removeEvent()
    this.$router.push({ name: 'AskWrabbitCategory' })
  }

  @State('ForecastModule') state: any;
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
}
