







































import UserService from '@/services/user'
import UserTypes from '@/store/types/UserTypes'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component
export default class ChooseAvatar extends Vue {
  async chooseAvatar (avatar: 'green'|'gray'|'pink') {
    this.loading(true)
    const [error] = await UserService.updateUser({ avatar_color: avatar })
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    await this.update()
    this.loading(false)
  }

  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @State('UtilsModule') state: any;
  @State('UserModule') user: any;
  @Action(`UserModule/${UserTypes.actions.SET_DATA}`) update: any;
}
