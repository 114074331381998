import to from 'await-to-js'
import axios from '@/utils/axios-api'
import { Store } from '@/interfaces/Group'

const PANEL_URL = process.env.VUE_APP_API
const FORECAST_URL = process.env.VUE_APP_API

export default class GroupService {
  static async store (data: Store) {
    return await to(axios.post(`${PANEL_URL}/user/custom_filter/create`, data)) as any
  }

  static async update (id: number, data: any) {
    return await to(axios.put(`${PANEL_URL}/custom_filter/${id}`, data)) as any
  }

  static async index () {
    return await to(axios.get(`${PANEL_URL}/user/custom_filters`)) as any
  }

  static async forecastByGroupId (id: string) {
    return await to(axios.get(`${FORECAST_URL}/forecast/custom_filter/${id}`)) as any
  }

  static async forecastDefault () {
    return await to(axios.get(`${FORECAST_URL}/forecast/custom_filter/default`)) as any
  }

  static async find (id: number) {
    return await to(axios.get(`${PANEL_URL}/custom_filter/${id}`)) as any
  }

  static async delete (id: number) {
    return await to(axios.delete(`${PANEL_URL}/custom_filter/${id}`)) as any
  }
}
