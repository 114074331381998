import { required, decimal, minValue } from 'vuelidate/lib/validators'

const validations = {
  createTrade: {
    entry_price: { required, decimal },
    take_profit_price: { required, decimal },
    take_profit_pct: { required },
    stop_loss_price: { required, decimal },
    stop_loss_pct: { required },
    leverage: { required },
    position: { required }
  },
  userAmount: {
    amount_to_risk_per_trade_usdt: { required, decimal, minValue: minValue(20) },
    leverage: { required }
  }
}

export default validations
