import Vue from 'vue'
import Toasted from 'vue-toasted'
import 'material-icons'

Vue.use(Toasted, { position: 'bottom-right' })

const duration = 4000

Vue.toasted.register('error', (error = null) => {
  if (error) {
    if (error.response && error.response.data) {
      if (error.response.data.error) return error.response.data.error.join(', ')
      if (error.response.data.message) return error.response.data.message
    } else if (error.message) {
      if (error.message.includes('Cannot read properties of undefined')) return 'Oops.. Algo salio mal, intenta mas tarde'
      return error.message
    }
  }
  return 'Oops.. Algo salio mal, intenta mas tarde'
},
{ type: 'error', icon: 'error_outline', duration })

Vue.toasted.register('warning', (payload = { message: 'Advertencia' }) => payload.message,
  { type: 'warning', icon: 'error_outline', duration })

Vue.toasted.register('success', (payload = { message: 'Èxito' }) => payload.message,
  { type: 'success', icon: 'check_circle_outline', duration })

Vue.toasted.register('info', (payload = { message: 'Informativo' }) => payload.message,
  { type: 'info', icon: 'help_outline', duration })
