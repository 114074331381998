



















import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import SymbolTypes from '@/store/types/SymbolTypes'
import NavigationTypes from '@/store/types/NavigationTypes'
import ForecastTypes from '@/store/types/ForecastTypes'
import Utils from '@/helpers/Utils'
import StateUser from '@/store/interfaces/User'
import TrackEvent from '@/utils/track-events'
import ForecastState from '@/store/interfaces/Forecast'

@Component({
  components: {
    'detail-symbol': () => import('@/components/dashboard/Forecast/DetailSymbol.vue'),
    CoinsTour: () => import('@/components/tours/Coins.vue'),
    ForecastTutorial: () => import('@/components/tutorials/Forecast.vue'),
    'symbol-select': () => import('@/components/dashboard/Forecast/SymbolSelect.vue'),
    fundamental: () => import('@/components/dashboard/Forecast/Fundamental.vue'),
    'input-search-market': () => import('@/components/dashboard/Forecast/InputSearchMarket.vue')
  }
})
export default class Forecast extends Vue {
  readyData = false
  dataComputed = { probability: 0 }
  type = 'fundamental'
  search = ''

  @Watch('$route', { immediate: true })
  async changeClock () {
    this.loading(true)
    if (!this.$route.params.symbol) this.$router.replace({ params: { symbol: 'BTC_USDT' }, query: { clock: '1d' } })
    this.readyData = false
    this.type = 'fundamental'
    await this.changeCurrentSymbolSelected()
    await this.summary({ symbol: this.$route.params.symbol.replace('_', '/'), clock: this.$route.query.clock || '1d' })
    this.readyData = true
    this.loading(false)
  }

  async mounted () {
    this.loading(true)
    await Utils.sleep(30)
    if (this.$route.name === 'Forecast') this.setBreadcrums([{ text: 'lateralMenu.home', to: { name: 'Home' } }, { text: 'lateralMenu.forecast', active: true }])
    const data: any = {}
    if (!this.$route.params.symbol) data.params = { symbol: this.stateSymbolModule.current_symbol_selected.symbol.replace('/', '_') || 'BTC_USDT' }
    if (!this.$route.query.clock) data.query = { clock: '30m' }
    if (Object.keys(data).length) await this.$router.replace(data)
    await this.changeCurrentSymbolSelected()
    this.readyData = true
    this.loading(false)
    TrackEvent.track('PV_Coins', { symbol: this.$route.params.symbol })
  }

  changeCurrentSymbolSelected () {
    const symbol = this.$route.params.symbol.replace('_', '/')
    this.updateCurrentSymbolSelected({
      logo_url: this.state.tickersFormatted[symbol].logo_url,
      symbol,
      major: this.state.marketsFormatted[symbol].major,
      minor: 'USDT'
    })
  }

  @State('ForecastModule') state!: ForecastState;
  @State('UserModule') stateUser!: StateUser;
  @State('SymbolModule') stateSymbolModule: any;
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Action(`ForecastModule/${ForecastTypes.actions.UPDATE_DATA_SUMMARY}`) summary: any
  @Action(`SymbolModule/${SymbolTypes.actions.UPDATE_CURRENT_SELECTED_SYMBOL}`) updateCurrentSymbolSelected: any;
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @State('UtilsModule') utilState: any;
}
