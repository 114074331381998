
























import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class InitOnboarding extends Vue {
  getUrlAvatar () {
    return require(`@/assets/images/presentation/step11_${this.user.avatar_color}.png`)
  }

  @State('UserModule') user: any;
  @State('UtilsModule') state: any;
}
