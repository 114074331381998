export default {
  mutations: {
    CONNECT_TO_METAMASK: 'CONNECT_TO_METAMASK',
    SET_DATA_METAMASK: 'SET_DATA_METAMASK',
    SET_BALANCE_BINANCE: 'SET_BALANCE_BINANCE',
    CHANGE_POLYGON_NETWORK: 'CHANGE_POLYGON_NETWORK',
    DELETE_METAMASK_CONNECTION: 'DELETE_METAMASK_CONNECTION'
  },
  getters: {
    GET_DATA: 'GET_DATA',
    GET_BINANCE_BALACE_DATA: 'GET_BINANCE_BALACE_DATA',
    GET_METAMASK_DATA: 'GET_METAMASK_DATA'
  },
  actions: {
    CONNECT_TO_METAMASK: 'CONNECT_TO_METAMASK',
    CHANGE_POLYGON_NETWORK: 'CHANGE_POLYGON_NETWORK',
    SET_DATA_METAMASK: 'SET_DATA_METAMASK',
    SET_BALANCE_BINANCE: 'SET_BALANCE_BINANCE',
    ADD_TOKEN_METAMASK: 'ADD_TOKEN_METAMASK',
    DELETE_METAMASK_CONNECTION: 'DELETE_METAMASK_CONNECTION'
  }
}
