






































































































































import NewWrabbitService from '@/services/newWrabbit'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component
export default class QuizOnboarding extends Vue {
  option: any = ''

  async nextStep () {
    if (this.option === 6) return this.$router.push({ name: 'OnboardingMeet' })
    this.loading(true)
    const main_goal = this.option === 1 ? 'learn'
      : this.option === 2 ? 'invest_popular_cryptos'
        : this.option === 3 ? 'trading'
          : this.option === 4 ? 'invest_moonshots' : 'protect_inflation'
    const [error] = await NewWrabbitService.setInvertorProfile({
      question_1: null,
      question_2: null,
      question_3: null,
      question_4: null,
      question_5: null,
      main_goal
    })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.$router.push({ name: 'OnboardingCustomizing' })
  }

  @State('UtilsModule') state: any;
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void;
}
