export default {
  mutations: {
    SET_DATA: 'SET_DATA',
    CHANGE_ENABLED_TRADING: 'CHANGE_ENABLED_TRADING'
  },
  getters: {
    GET_PAYLOAD: 'GET_PAYLOAD'
  },
  actions: {
    SET_DATA: 'SET_DATA',
    CHANGE_ENABLED_TRADING: 'CHANGE_ENABLED_TRADING'
  }
}
