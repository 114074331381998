import { Summary } from '@/interfaces/Forecast'
import axios from '@/utils/axios-api'
import to from 'await-to-js'
import db from '@/firebase'

const FORECAST_URL = process.env.VUE_APP_API
const PANEL_URL = process.env.VUE_APP_API
const CACHE_URL = process.env.VUE_APP_CACHE_API
const PROFIT_MODEL_URL = process.env.VUE_APP_PROFIT_MODEL_API

export default class ForecastService {
  static async summary (data: Summary = {
    symbol: 'BTCUSDT',
    clock: '1h',
    priceType: 'time'
  }) {
    return to(axios.get(`${FORECAST_URL}/forecast/${data.symbol}`, {
      params: {
        clock: data.clock,
        price_type: data.priceType
      }
    })) as any
  }

  static async multi_clock (symbol = 'BTCUSDT') {
    return to(axios.get(`${FORECAST_URL}/multi_clock/${symbol}?price_type=time`)) as any
  }

  static async getFavorites () {
    return to(axios.get(`${PANEL_URL}/user/favorites`)) as any
  }

  static async findTicker (symbol: string) {
    return to(axios.get(`${CACHE_URL}/ticker/${symbol}`)) as any
  }

  static async getVolatilityBySymbol (symbol: string) {
    return to(axios.get(`${FORECAST_URL}/volatility/${symbol}`)) as any
  }

  static async getTimeframes () {
    return to(axios.get(`${FORECAST_URL}/timeframes`)) as any
  }

  static async setPriceAlert (symbol: string, clock: string, wrabbitScore: any, entryPrice: any, priceType = 'time') {
    return to(axios.post(`${PANEL_URL}/trade_setup/create`, {
      symbol,
      clock,
      price_type: priceType,
      wrabbit_final_score: wrabbitScore,
      entry_price: entryPrice
    })) as any
  }

  static forecastPrediction (symbol: string) {
    return to(axios.get(`${PROFIT_MODEL_URL}/score/${symbol}`)) as any
  }

  static async getFundamentalAnalysis (symbol: string) {
    const row: any = await db.collection('fundamental_analysis').doc(symbol).get()
    if (row.exists) {
      const data: any = row.data()
      return [null, data.data]
    }
    return [{ message: 'Not found' }]
  }

  static async getFundamentalTrendChart (symbol: string) {
    const row: any = await db.collection('fundamental_trend_chart').doc(symbol).get()
    if (row.exists) {
      const data: any = row.data()
      return [null, { created_on: data.created_on, chart: JSON.parse(JSON.parse(data.data)) }]
    }
    return [{ message: 'Not found' }]
  }
}
