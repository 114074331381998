





















































































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import NavigationTypes from '@/store/types/NavigationTypes'
import BasketsService from '@/services/baskets'
import EventBus from '@/event-bus'
import TrackEvents from '@/utils/track-events'

@Component({
  components: {
    Basket: () => import('@/components/dashboard/Basket.vue'),
    People: () => import('@/components/dashboard/People.vue'),
    BestTradeContent: () => import('./ask-wrabbit/BestTradeContent.vue'),
    TableSymbolSelect: () => import('@/components/dashboard/Forecast/TableSymbolSelect.vue'),
    ChooseTrade: () => import('@/components/trade-ideas/ChooseTrade.vue'),
    PortfolioTutorial: () => import('@/components/tutorials/Portfolio.vue'),
    InvestTour: () => import('@/components/tours/Invest.vue')
  }
})
export default class Discover extends Vue {
  readyData = false
  search = ''
  results: any = []
  people = [1, 2, 3, 4, 5]
  trades: any = []
  images = ['rojo', 'morado', 'verde', 'azul', 'rosa']
  risks: any = [{
    name: this.$t('baskets.low'),
    value: 'low',
    selected: true
  }, {
    name: this.$t('baskets.medium'),
    value: 'medium',
    selected: false
  }, {
    name: this.$t('baskets.high'),
    value: 'high',
    selected: false
  }];

  baskets: any = []
  wrabbitSelection: any = false

  mounted () {
    this.prepareData()
    TrackEvents.track('PV_Invest')
  }

  async prepareData () {
    this.setBreadcrums([{ text: 'lateralMenu.home', to: { name: 'Home' } }, { text: 'lateralMenu.discover', active: true }])
    await Promise.all([
      this.getBasketWrabbitSelection(),
      this.getBasketsByRisk()
    ])
    this.readyData = true
  }

  setActiveRisk (risk: any) {
    this.risks.forEach((item: any) => {
      item.selected = false
    })
    risk.selected = true
    this.getBasketsByRisk()
  }

  async getBasketsByRisk () {
    this.loading(true)
    const activeRisk = this.risks.find((item: any) => item.selected)
    const [error, response] = await BasketsService.getBaskets(activeRisk.value)
    this.loading(false)
    if (error) this.$toasted.global.error(error)
    this.baskets = response.data.map((el: any) => ({ ...el, show_basket: true }))
  }

  async getBasketWrabbitSelection () {
    this.loading(true)
    const [error, response] = await BasketsService.getBasketWrabbitSelection()
    this.loading(false)
    if (error) this.$toasted.global.error(error)
    this.wrabbitSelection = response.data
  }

  searchBaskets () {
    // @ts-ignore
    this.baskets = this.baskets.map((el: any) => ({ ...el, show_basket: true }))
    // @ts-ignore
    this.baskets = this.baskets.map((el: any) => {
      return {
        ...el,
        show_basket: el.basket_name.toLowerCase().includes(this.search.toLowerCase())
      }
    })
  }

  openModalTutorial () { EventBus.$emit('open-modal-portfolio-tutorial') }

  @State('ForecastModule') state: any;
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @State('UtilsModule') utilState: any;
}
