




























































































import { Component, Vue } from 'vue-property-decorator'
import BasketsService from '@/services/baskets'
import EventBus from '@/event-bus'
import { Action, Getter } from 'vuex-class'
import NavigationTypes from '@/store/types/NavigationTypes'

@Component({
  components: {
    Basket: () => import('@/components/dashboard/Basket.vue'),
    PortfolioTutorial: () => import('@/components/tutorials/Portfolio.vue'),
    SimpleSymbolSelect: () => import('@/components/dashboard/Forecast/SimpleSymbolSelect.vue')
  }
})
export default class AllBaskets extends Vue {
  baskets: any = [];
  basketsToShow: any = [];
  search = '';
  activeFilter = 'all';
  allBaskets = [];
  favorites = [];
  invested = [];
  noDataBaskets = ''

  async mounted () {
    this.setBreadcrums([
      { text: 'lateralMenu.home', to: { name: 'Home' } },
      { text: 'lateralMenu.discover', to: { name: 'Discover' } },
      { text: 'All Baskets', active: true }
    ])
    await this.getBaskets()
    this.setActiveFilter(this.activeFilter)
  }

  setActiveFilter (filter: string) {
    this.activeFilter = filter
    if (filter === 'favorites') {
      this.noDataBaskets = 'Aún no tienes portafolios agregados a favoritos'
      this.basketsToShow = this.favorites
    }
    if (filter === 'investments') {
      this.noDataBaskets = 'Aún no has invertido en ningún portafolio'
      this.basketsToShow = this.invested
    }
    if (filter === 'all') {
      this.basketsToShow = this.allBaskets
    }
  }

  async getBaskets () {
    this.loading(true)
    const [error, data] = await BasketsService.getBaskets('all')
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.baskets = data.data
    await this.getUserBaskets()
  }

  async getUserBaskets () {
    this.loading(true)
    const [error, data] = await BasketsService.getUserBaskets()
    if (error) return this.$toasted.global.error(error)
    this.loading(false)
    this.favorites = data.data.filter((el: any) => el.is_favorite).map((el: any) => el.basket_id)
    this.invested = data.data.filter((el: any) => el.is_invested).map((el: any) => el.basket_id)
    // @ts-ignore
    this.favorites = this.baskets.filter((el: any) => this.favorites.includes(el.id)).map((el: any) => ({ ...el, show_basket: true }))
    // @ts-ignore
    this.invested = this.baskets.filter((el: any) => this.invested.includes(el.id)).map((el: any) => ({ ...el, show_basket: true }))
    this.allBaskets = this.baskets.map((el: any) => ({ ...el, show_basket: true }))
  }

  searchBaskets () {
    // @ts-ignore
    this.basketsToShow = this.basketsToShow.map((el: any) => ({ ...el, show_basket: true }))
    // @ts-ignore
    this.basketsToShow = this.basketsToShow.map((el: any) => {
      return {
        ...el,
        show_basket: el.basket_name.toLowerCase().includes(this.search.toLowerCase())
      }
    })
  }

  cleanFilters () {
    this.basketsToShow = this.basketsToShow.map((el: any) => ({ ...el, show_basket: true }))
  }

  applyBasketsFilters (filters: any) {
    const symbolsFiltered = filters.symbol_filter.map((el: any) => el.symbol)
    const riskFiltered = filters.risk_filter.map((el: any) => el.risk_id)

    if (symbolsFiltered.length > 0 && riskFiltered.length > 0) {
      this.basketsToShow = this.basketsToShow.map((el: any) => {
        for (const symbol of symbolsFiltered) {
          if (el.basket_allocations[0].allocation.labels.includes(symbol) && riskFiltered.includes(el.risk_profile)) {
            return { ...el, show_basket: true }
          } else {
            return { ...el, show_basket: false }
          }
        }
      })
    } else if (symbolsFiltered.length > 0) {
      this.basketsToShow = this.basketsToShow.map((el: any) => {
        for (const symbol of symbolsFiltered) {
          if (el.basket_allocations[0].allocation.labels.includes(symbol)) {
            return { ...el, show_basket: true }
          } else {
            return { ...el, show_basket: false }
          }
        }
      })
    } else if (riskFiltered.length > 0) {
      this.basketsToShow = this.basketsToShow.map((el: any) => {
        for (const risk of riskFiltered) {
          if (riskFiltered.includes(el.risk_profile)) {
            return { ...el, show_basket: true }
          } else {
            return { ...el, show_basket: false }
          }
        }
      })
    }
  }

  openModalTutorial () {
    EventBus.$emit('open-modal-portfolio-tutorial')
  }

  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void;
}
