import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL = process.env.VUE_APP_TRADING_BOT_API

export default class BinanceService {
  static async getBalanceUser () {
    return await to(axios.get(`${URL}/user/usdt_balance`)) as any
  }

  static async deleteBinanceCredentials () {
    return await to(axios.post(`${URL}/user/binance_delete`)) as any
  }

  static async buyFutureTrade (data: {
    trade_id?: number,
    side: 'ENTRY' | 'EXIT',
    symbol?: string,
    investment_amount?: string,
    leverage?: string,
    position_side?: 'LONG' | 'SHORT',
    order_price?: string
  }) {
    return await to(axios.post(`${URL}/bot/futures_trade`, data)) as any
  }
}
