



















































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import PlanService from '@/services/plans'
import Utils from '@/helpers/Utils'
import UserTypes from '@/store/types/UserTypes'
import AuthTypes from '@/store/types/AuthTypes'
import UserState from '@/store/interfaces/User'
import TrackEvent from '@/utils/track-events'

@Component({
  components: {
    HeaderFullPageDashboard: () => import('@/components/HeaderFullPageDashboard.vue')
  }
})
export default class SelectTypePlan extends Vue {
  readyData = false
  wrabbit = false
  whyWrabbit = false
  trading = true
  money = false
  discounts = false
  careful = false
  other = false
  dataPrices = {
    wrabbit_standard: { currency: '', link: '' },
    wrabbit_alpha: { currency: '', link: '' },
    only_sessions: { currency: '', link: '' },
    one_week_trial: { currency: '', link: '' }
  }

  async mounted () {
    this.getPaymentLinks()
    this.validateUserPremium()
    TrackEvent.track('PV_Checkout')
  }

  async getPaymentLinks () {
    this.loading(true)
    const [error, response] = await PlanService.getPaymentLinks()
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.user.country = 'mexico'
    const currency = this.user.country === 'mexico' ? 'mxn' : 'usd'
    this.dataPrices.wrabbit_standard = response.data.find((item: any) => item.name === 'wrabbit_standard' && item.currency === currency)
    this.dataPrices.wrabbit_alpha = response.data.find((item: any) => item.name === 'wrabbit_alpha' && item.currency === currency)
    this.dataPrices.only_sessions = response.data.find((item: any) => item.name === 'only_sessions' && item.currency === currency)
    this.dataPrices.one_week_trial = response.data.find((item: any) => item.name === 'one_week_trial' && item.currency === currency)
    this.readyData = true
  }

  async goToStripe (plan: 'wrabbit_standard' | 'one_week_trial' | 'wrabbit_alpha') {
    const planSelected = this.dataPrices[plan]
    const element = document.createElement('a')
    element.href = planSelected.link
    element.target = '_blank'
    element.click()
    element.remove()
    TrackEvent.track('AC_StripeLink', { currency: planSelected.currency, plan })
  }

  async validateUserPremium () {
    if (this.$route.name !== 'SelectTypePlan') return
    if (this.user.subscription_level !== 'free') return this.$router.push({ name: 'Home' })
    await Utils.sleep(3000)
    await this.updateDataUser()
    this.validateUserPremium()
  }

  async signOff () {
    await this.logout()
    await this.$router.push({ name: 'Login' })
  }

  @State('UserModule') user!: UserState;
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void;
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @Action(`UserModule/${UserTypes.actions.SET_DATA}`) updateDataUser: any;
  @Action(`AuthModule/${AuthTypes.actions.LOGOUT}`) logout: any;
}
