





























































































































































































import NavigationTypes from '@/store/types/NavigationTypes'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import { v4 as uuidv4 } from 'uuid'
import MentorsService from '@/services/mentors'

@Component
export default class Detail extends Vue {
  mentor: any = {}
  readyData = false
  items = [
    { id: uuidv4(), like: true },
    { id: uuidv4(), like: false },
    { id: uuidv4(), like: true },
    { id: uuidv4(), like: false },
    { id: uuidv4(), like: true },
    { id: uuidv4(), like: false },
    { id: uuidv4(), like: true },
    { id: uuidv4(), like: false },
    { id: uuidv4(), like: true }
  ]

  mounted () {
    this.prepareData()
  }

  prepareData () {
    if (this.$route.name === 'MentorDetailMain') {
      this.setBreadcrums([
        { text: 'lateralMenu.home', to: { name: 'Home' } },
        { text: 'Mentors', active: true }
      ])
      this.getProfileMain()
    } else if (this.$route.params.id) {
      this.setBreadcrums([
        { text: 'lateralMenu.home', to: { name: 'Home' } },
        { text: 'lateralMenu.discover', to: { name: 'Discover' } },
        { text: 'Mentors', active: true }
      ])
      this.getProfileById()
    } else {
      this.$toasted.global.error({ message: 'Hubo un error al obtener la data del mentor' })
      this.$router.push({ name: 'Home' })
    }
  }

  async getProfileMain () {
    this.loading(true)
    const [error, response] = await MentorsService.getMentorMain()
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.mentor = response.data
    this.readyData = true
  }

  async getProfileById () {
    this.loading(true)
    const [error, response] = await MentorsService.getMentor(this.$route.params.id)
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.mentor = response.data
    this.readyData = true
  }

  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void;
  @State('ForecastModule') state: any;
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @State('UserModule') stateUser: any;
}
