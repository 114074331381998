import to from 'await-to-js'
import axios from '@/utils/axios-api'
import { NewLead } from '@/interfaces/Lead'

const PANEL_URL = process.env.VUE_APP_API

export default class LeadService {
  static async create (data: NewLead) {
    return await to(axios.post(`${PANEL_URL}/lead/register`, data)) as any
  }
}
