import to from 'await-to-js'
import axios from '@/utils/axios-api'
import { AddRemove } from '@/interfaces/Favorite'

const PANEL_URL = process.env.VUE_APP_API

export default class FavoriteService {
  static async addOrRemove (data: AddRemove) {
    return await to(axios.post(`${PANEL_URL}/user/favorites/modify`, data)) as any
  }
}
