import { required, email } from 'vuelidate/lib/validators'

const validations = {
  validateCode: {
    email: { required, email },
    code: { required }
  }
}

export default validations
