





























































































































import NewWrabbitService from '@/services/newWrabbit'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class MeetOnboarding extends Vue {
  step = 1
  progress = 5
  keyLocalStorge = 'onboarding_response'
  responses: any = {
    a1: 'yes',
    a2: 'stability',
    a2Other: '',
    a3: 'long_term',
    a4: 'small_amount',
    a5: 'angry'
  }

  questions = [
    {
      emoji: '',
      question: this.$t('presentation.options_choose_goal.0'),
      value: 'stability'
    },
    {
      emoji: '',
      question: this.$t('presentation.options_choose_goal.1'),
      value: 'car'
    },
    {
      emoji: '',
      question: this.$t('presentation.options_choose_goal.2'),
      value: 'house'
    },
    {
      emoji: '',
      question: this.$t('presentation.options_choose_goal.3'),
      value: 'business'
    },
    {
      emoji: '',
      question: this.$t('presentation.options_choose_goal.4'),
      value: 'debt'
    },
    {
      emoji: '',
      question: this.$t('presentation.options_choose_goal.5'),
      value: 'other'
    }
  ]

  questionsa3 = [
    {
      question: this.$t('presentation.options_time.1'),
      value: 'long_term'
    },
    {
      question: this.$t('presentation.options_time.2'),
      value: 'one_year'
    },
    {
      question: this.$t('presentation.options_time.3'),
      value: 'three_months'
    }
  ]

  questionsa4 = [
    {
      question: this.$t('presentation.options_invest.1'),
      value: 'small_amount'
    },
    {
      question: this.$t('presentation.options_invest.2'),
      value: 'medium_amount'
    },
    {
      question: this.$t('presentation.options_invest.3'),
      value: 'big_amount'
    }
  ]

  questionsa5 = [
    {
      question: this.$t('presentation.options_down.1'),
      value: 'angry'
    },
    {
      question: this.$t('presentation.options_down.2'),
      value: 'understand'
    },
    {
      question: this.$t('presentation.options_down.3'),
      value: 'calm'
    },
    {
      question: this.$t('presentation.options_down.4'),
      value: 'invest'
    }
  ]

  mounted () { this.initData() }

  initData () {
    this.step = (this.$route.query.a) ? Number(this.$route.query.a) : 1
    const data = localStorage.getItem(this.keyLocalStorge)
    if (data) this.responses = JSON.parse(data)
    this.progress = this.getPercentageCurrentStep()
  }

  formattedValueProgress (item: any) { return `<span style="font-weight:500; font-size:13px;">${item.currentValue}%</span>` }

  async nextStep () {
    localStorage.setItem(this.keyLocalStorge, JSON.stringify(this.responses))
    if (this.step === 5) {
      this.loading(true)
      const [error] = await NewWrabbitService.setInvertorProfile({
        question_1: this.responses.a1,
        question_2: this.responses.a2 === 'other' ? this.responses.a2Other : this.responses.a2,
        question_3: this.responses.a3,
        question_4: this.responses.a4,
        question_5: this.responses.a5,
        main_goal: null
      })
      this.loading(false)
      if (error) return this.$toasted.global.error(error)
      return this.$router.push({ name: 'OnboardingCustomizing' })
    }
    this.step++
    this.progress = this.getPercentageCurrentStep()
    this.$router.replace({ query: { a: this.step.toString() } })
  }

  getPercentageCurrentStep () {
    if (this.step === 2) return 20
    if (this.step === 3) return 40
    if (this.step === 4) return 60
    if (this.step === 5) return 80
    else return 0
  }

  backStep () {
    this.step--
    this.progress = this.getPercentageCurrentStep()
    this.$router.replace({ query: { a: this.step.toString() } })
    this.initData()
  }

  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
}
