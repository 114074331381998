import { createChart, LineStyle, isBusinessDay } from 'lightweight-charts'
import EventBus from '@/event-bus'
import moment from 'moment'

export default class TradingviewService {
  static getHorizontalLines (data: any, similar = false, pattern = null) {
    let horizontalLines: any = []

    if (similar) {
      if (data.horizontal_lines[`horizontal_lines_${pattern}`]) {
        horizontalLines = data.horizontal_lines[`horizontal_lines_${pattern}`].map(
          (value: any) => ({
            value,
            color: '#00CD9D'
          })
        )
      }

      return horizontalLines
    }

    data.horizontal_lines.forEach((line: any) => {
      horizontalLines.push({
        value: line,
        color: 'rgba(0,205,157,0.6)'
      })
    })

    return horizontalLines
  }

  static setSetupLine (mychart: any, price: any, show = true) {
    if (mychart.priceLine) {
      mychart.priceLineTag.removePriceLine(mychart.priceLine)
      if (!show) return 0
    }
    mychart.priceLine = mychart.priceLineTag.createPriceLine({
      price: Number(price),
      color: '#7749F8',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      axisLabelVisible: true,
      title: '',
      crosshairMarkerVisible: false
    })
  }

  static formatValueFromMinor (minor: any, value: any, lastPrice: any) {
    if (minor === 'BTC' || minor === 'btc') {
      value = (value * (1 / Math.pow(10, 8))).toFixed(8)
    }

    if ((minor === 'USDT' || minor === 'usdt') && lastPrice < 1) {
      value = value.toFixed(6)
    }

    if ((minor === 'USDT' || minor === 'usdt') && lastPrice >= 1) {
      value = value.toFixed(2)
    }

    return value
  }

  static setMarkers (mychart: any, markers: any, show: boolean, prices: any, chartType: string) {
    if (show) {
      let markertsAbonormalPrices: any = []
      markers.forEach((abnormalPrice: any) => {
        if (abnormalPrice * 1000 > prices[0][0]) {
          markertsAbonormalPrices.push({
            time: abnormalPrice * 1000,
            position: 'belowBar',
            color: '#f09322',
            shape: 'circle',
            text: ''
          })
        }
      })

      markertsAbonormalPrices = [...mychart.markersStartEndLines, ...markertsAbonormalPrices]

      chartType === 'Candlestick'
        ? mychart.candleSeries.setMarkers(markertsAbonormalPrices)
        : mychart.pricesSeries.setMarkers(markertsAbonormalPrices)

      mychart.smaLine50.applyOptions({
        color: 'rgba(243, 186, 47, 0.85)'
      })

      mychart.smaLine200.applyOptions({
        color: 'rgba(98, 126, 234, 0.85)'
      })
    } else {
      mychart.smaLine50.applyOptions({
        color: 'rgba(243, 186, 47, 0)'
      })

      mychart.smaLine200.applyOptions({
        color: 'rgba(98, 126, 234, 0)'
      })

      chartType === 'Candlestick'
        ? mychart.candleSeries.setMarkers(mychart.markersStartEndLines)
        : mychart.pricesSeries.setMarkers(mychart.markersStartEndLines)
    }
  }

  static updateHorizontalLines (
    mychart: any,
    show: any,
    data: any,
    minor: any,
    lastPrice: any,
    similar = false,
    pattern = null,
    trendlineColor: string,
    chartType: string
  ) {
    const priceLines: any = []
    if (show) {
      const horizontalLines = TradingviewService.getHorizontalLines(data, similar, pattern)

      horizontalLines.forEach((line: any) => {
        const priceLine = {
          price: TradingviewService.formatValueFromMinor(minor, line.value, lastPrice),
          color: 'rgba(244, 174, 33, .8)',
          lineWidth: 1,
          lineStyle: LineStyle.Solid,
          axisLabelVisible: true,
          title: TradingviewService.formatValueFromMinor(minor, line.value, lastPrice),
          crosshairMarkerVisible: false
        }
        priceLines.push(mychart.seriesHorizontalLines.createPriceLine(priceLine))
      })
      mychart.priceLines = priceLines

      const trendlineSeriesArr: any = []
      mychart.trendLinesData.forEach((element: any) => {
        const trendlineSeries = mychart.chart.addLineSeries({
          color: trendlineColor,
          lineWidth: 2,
          crosshairMarkerVisible: false
        })
        trendlineSeries.applyOptions({
          priceLineVisible: false,
          lineStyle: LineStyle.Solid
        })
        trendlineSeriesArr.push(trendlineSeries)
        trendlineSeries.setData(element)
      })

      mychart.trendlines = trendlineSeriesArr
    } else {
      mychart.priceLines.forEach((priceLine: any) => {
        mychart.seriesHorizontalLines.removePriceLine(priceLine)
      })

      mychart.trendlines.forEach((trendline: any) => {
        mychart.chart.removeSeries(trendline)
      })
    }
  }

  // eslint-disable-next-line
  static createChart(
    div: string,
    prices: any,
    union: any,
    forecast: any,
    trendlines: any,
    forecastColor: string,
    chartType: string,
    toolTip: any,
    minor: any,
    lastPrice: any,
    annotations: any,
    startEndVlines: any,
    drawForecast: boolean
  ) {
    const lang = 'es-MX'
    let pricesData: any
    let pricesDataSerie: any

    if (chartType !== 'Candlestick') {
      pricesData = prices.map((el: any) => ({
        time: el[0],
        value: el[1]
      }))
    } else {
      pricesDataSerie = prices.map((el: any) => ({
        time: el[0],
        value: el[1]
      }))
      pricesData = prices.map((el: any) => ({
        time: el[0],
        open: el[1],
        high: el[2],
        low: el[3],
        close: el[4]
      }))
    }

    const forecastData = forecast.map((el: any) => ({
      time: el[0],
      value: el[1]
    }))
    const unionData = union.map((el: any) => ({
      time: el[0],
      value: el[1]
    }))
    const trendLinesData: any = []

    const divChart: any = document.getElementById(div)

    divChart.innerHTML = ''
    const chart = createChart(divChart, {
      width: divChart.offsetWidth,
      height: 300,
      layout: {
        textColor: '#d1d4dc',
        backgroundColor: 'transparent'
      },
      leftPriceScale: {
        visible: false
      },
      rightPriceScale: {
        visible: false
      },
      handleScroll: false,
      handleScale: false,
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        },
        vertLine: {
          visible: true,
          style: 0,
          width: 2,
          color: 'rgba(32, 38, 46, 0.9)',
          labelVisible: true
        }
      },
      timeScale: {
        rightOffset: 0,
        barSpacing: 3,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        borderColor: '#fff000',
        visible: true,
        timeVisible: true,
        secondsVisible: false,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          return moment(time).format('MM/DD H:mm')
        }
      },
      localization: {
        locale: lang, // DYNAMIC
        timeFormatter: (businessDayOrTimestamp: any) => {
          return moment(new Date(businessDayOrTimestamp)).format('MMMM Do YYYY, H:mm')
        }
      },
      grid: {
        vertLines: {
          visible: false
        },
        horzLines: {
          visible: false
        }
      }
    })

    let pricesSeries: any
    let unionSeries: any
    let candleSeries: any
    let forecastSeries: any
    let priceLineTag: any

    const seriesHorizontalLines = chart.addLineSeries({
      color: 'rgba(0, 120, 255, 0)',
      lineWidth: 1,
      crosshairMarkerVisible: false,
      lastValueVisible: false,
      priceLineVisible: false
    })

    unionSeries = 0

    if (chartType !== 'Candlestick') {
      if (drawForecast) {
        pricesSeries = chart.addAreaSeries({
          topColor: 'rgba(112, 226, 206, 0)',
          bottomColor: 'rgba(112, 226, 206, 0)',
          lineColor: 'grey',
          lineWidth: 2
        })
      } else {
        pricesSeries = chart.addAreaSeries({
          lineColor: '#6ef8d7',
          topColor: 'rgba(110, 248, 215, 0.20)',
          bottomColor: 'rgba(110, 248, 215, 0.05)',
          lineWidth: 2,
          crosshairMarkerVisible: true,
          lastValueVisible: false,
          priceLineVisible: false
        })
      }

      pricesSeries.applyOptions({
        priceLineVisible: false,
        priceLineWidth: 2,
        priceLineColor: '#4682B4',
        priceLineStyle: 3
      })
    } else {
      candleSeries = chart.addCandlestickSeries({
        upColor: '#009975',
        downColor: '#F2007E',
        borderDownColor: '#F2007E',
        borderUpColor: '#009975',
        wickDownColor: '#F2007E',
        wickUpColor: '#009975'
      })

      candleSeries.applyOptions({
        priceLineVisible: false
      })
    }

    if (drawForecast) {
      priceLineTag = chart.addLineSeries({
        color: '#959595',
        lineWidth: 1,
        crosshairMarkerVisible: false,
        lastValueVisible: false,
        priceLineVisible: false
      })

      unionSeries = chart.addAreaSeries({
        topColor: 'rgba(136, 0, 204, 0)',
        bottomColor: 'rgba(136, 0, 204, 0)',
        lineColor: forecastColor,
        lineWidth: 2
      })

      unionSeries.applyOptions({
        priceLineVisible: false,
        priceLineWidth: 2,
        priceLineColor: '#4682B4',
        priceLineStyle: 3
      })
      forecastSeries = chart.addAreaSeries({
        topColor: 'rgba(0, 126, 230, 0)',
        bottomColor: 'rgba(0, 126, 230, 0)',
        lineColor: forecastColor,
        lineWidth: 2
      })

      forecastSeries.applyOptions({
        priceLineVisible: false,
        priceLineWidth: 2,
        priceLineColor: '#4682B4',
        priceLineStyle: 3
      })
    }

    const markersStartEndLines: any = [
      {
        time: pricesData[0].time,
        position: 'belowBar',
        color: 'white',
        shape: 'line'
      },
      {
        time: pricesData[pricesData.length - 1].time,
        position: 'belowBar',
        color: '#F5B835',
        shape: 'line'
      }
    ]

    if (chartType !== 'Candlestick') {
      pricesSeries.setData(pricesData)
      seriesHorizontalLines.setData([...pricesData, ...unionData, ...forecastData])
      if (drawForecast) {
        priceLineTag.setData([...pricesData, ...unionData, ...forecastData])
        pricesSeries.setMarkers(markersStartEndLines)
      }
    } else {
      candleSeries.setData(pricesData)
      seriesHorizontalLines.setData([...pricesDataSerie, ...unionData, ...forecastData])
      if (drawForecast) {
        candleSeries.setMarkers(markersStartEndLines)
        priceLineTag.setData([...pricesDataSerie, ...unionData, ...forecastData])
      }
    }

    const pricesForAvg = chartType !== 'Candlestick' ? pricesData : pricesDataSerie
    const smaData50 = TradingviewService.calculateSMA(pricesForAvg, 50)

    const smaLine50 = chart.addLineSeries({
      color: 'rgba(243, 186, 47, 0)',
      lineWidth: 2,
      crosshairMarkerVisible: false,
      priceLineVisible: false
    })
    smaLine50.setData(smaData50)
    const smaData200 = this.calculateSMA(pricesForAvg, 25)
    const smaLine200 = chart.addLineSeries({
      color: 'rgba(98, 126, 234, 0)',
      lineWidth: 2,
      crosshairMarkerVisible: false,
      priceLineVisible: false
    })
    smaLine200.setData(smaData200)
    if (drawForecast) {
      unionSeries.setData(unionData)
      forecastSeries.setData(forecastData)
    }

    chart.subscribeCrosshairMove((param: any) => {
      if (!param.time) {
        // this.eventService.triggerPriceHasChanged('original')
        return 0
      }
      TradingviewService.getPrice(
        param,
        pricesSeries,
        unionSeries,
        forecastSeries,
        minor,
        lastPrice,
        candleSeries
      )
    })

    if (trendlines && trendlines.length) {
      trendlines.forEach((trendline: any) => {
        const dataTrendlineFormated = trendline.map((el: any) => ({
          time: el[0],
          value: el[1]
        }))
        trendLinesData.push(dataTrendlineFormated)
      })
    }

    chart.timeScale().fitContent()

    return {
      chart,
      seriesHorizontalLines,
      priceLineTag,
      trendLinesData,
      pricesSeries,
      smaLine50,
      smaLine200,
      candleSeries,
      markersStartEndLines
    }
  }

  businessDayToString (businessDay: any) {
    return businessDay.year + '-' + businessDay.month + '-' + businessDay.day
  }

  static async getPrice (
    param: any,
    pricesSeries: any,
    unionSeries: any,
    forecastSeries: any,
    minor: any,
    lastPrice: any,
    candleSeries: any
  ) {
    let price = param.seriesPrices.get(pricesSeries)

    if (!price) {
      price = param.seriesPrices.get(unionSeries)
    }
    if (!price) {
      price = param.seriesPrices.get(forecastSeries)
    }
    if (!price) {
      price = param.seriesPrices.get(candleSeries).close
    }
    EventBus.$emit('priceHasChanged', price)
  }

  static calculateSMA (data: any, count: any) {
    const avg = function (data: any) {
      let sum = 0
      for (let i = 0; i < data.length; i++) {
        sum += data[i].value
      }
      return sum / data.length
    }

    const result = []
    for (let i = count - 1, len = data.length; i < len; i++) {
      const val = avg(data.slice(i - count + 1, i))
      result.push({
        time: data[i].time,
        value: val
      })
    }

    return result
  }

  static async setTradeDetails (chart: any, data: any) {
    const priceLineStopLoss = {
      price: data.stop_loss_price,
      color: '#FD0084',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      axisLabelVisible: true,
      title: data.stop_loss_price,
      crosshairMarkerVisible: false
    }
    const priceLineTakeProfit = {
      price: data.take_profit_price,
      color: '#4DD4AC',
      lineWidth: 1,
      lineStyle: LineStyle.Solid,
      axisLabelVisible: true,
      title: data.take_profit_price,
      crosshairMarkerVisible: false
    }
    chart.seriesHorizontalLines.createPriceLine(priceLineStopLoss)
    chart.seriesHorizontalLines.createPriceLine(priceLineTakeProfit)

    if (chart.pricesSeries) {
      chart.pricesSeries.setMarkers([
        {
          time: data.entry_timestamp * 1000,
          position: 'belowBar',
          color: 'white',
          shape: 'circle',
          text: 'Entry price'
        }
      ])
    } else {
      chart.candleSeries.setMarkers([
        {
          time: data.entry_timestamp * 1000,
          position: 'belowBar',
          color: 'white',
          shape: 'circle',
          text: 'Entry price'
        }
      ])
    }
  }

  static async createSecondaryChart (div: string, data: any) {
    const divChart: any = document.getElementById(div)

    divChart.innerHTML = ''
    const chart = createChart(divChart, {
      width: divChart.offsetWidth,
      height: 300,
      layout: {
        textColor: '#d1d4dc',
        backgroundColor: 'transparent'
      },
      leftPriceScale: {
        visible: false
      },
      rightPriceScale: {
        visible: false
      },
      handleScroll: false,
      handleScale: false,
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        },
        vertLine: {
          visible: true,
          style: 0,
          width: 2,
          color: 'rgba(32, 38, 46, 0.9)',
          labelVisible: true
        }
      },
      timeScale: {
        rightOffset: 0,
        barSpacing: 3,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        borderColor: '#fff000',
        visible: true,
        timeVisible: true,
        secondsVisible: false,
        tickMarkFormatter: (time: any) => {
          return moment(time * 1000).format('MM/DD H:mm')
        }
      },
      localization: {
        locale: 'es-MX', // DYNAMIC
        timeFormatter: (businessDayOrTimestamp: any) => {
          return moment(new Date(businessDayOrTimestamp * 1000)).format('MMMM Do YYYY, H:mm')
        }
      },
      grid: {
        vertLines: {
          visible: false
        },
        horzLines: {
          visible: false
        }
      }
    })

    const candleSeries = chart.addCandlestickSeries({
      upColor: '#009975',
      downColor: '#F2007E',
      borderDownColor: '#F2007E',
      borderUpColor: '#009975',
      wickDownColor: '#F2007E',
      wickUpColor: '#009975',
      priceLineVisible: false
    })

    const candleData = data.prices_ohlc.map((el: any) => ({
      time: el[0],
      open: el[1],
      high: el[2],
      low: el[3],
      close: el[4]
    }))

    // eslint-disable-next-line prefer-spread
    const max = Math.max.apply(
      Math,
      candleData.map((o: any) => o[2])
    )
    // eslint-disable-next-line prefer-spread
    const min = Math.min.apply(
      Math,
      candleData.map((o: any) => o[3])
    )

    const values: any = []
    const staticTime = new Date().getTime()
    for (let i = min; i < max; i++) {
      values.push({
        time: staticTime,
        value: i
      })
    }

    const trendLines = data.trend_lines.map((el: any) => ({
      time: el[0],
      value: el[1]
    }))
    const trendlineSeries = chart.addLineSeries({
      color: data.trend_lines_color,
      lineWidth: 2,
      crosshairMarkerVisible: false,
      priceLineVisible: false
    })

    const markers: any = [
      {
        time: data.start_vline,
        position: 'belowBar',
        color: 'white',
        shape: 'line'
      },
      {
        time: data.end_vline,
        position: 'belowBar',
        color: '#F5B835',
        shape: 'line'
      }
    ]

    candleSeries.setData(candleData)
    trendlineSeries.setData(trendLines)
    trendlineSeries.setMarkers(markers)
    chart.timeScale().fitContent()

    const toolTip = document.createElement('div')
    toolTip.className = 'floating-tooltip-2'
    divChart.appendChild(toolTip)

    chart.subscribeCrosshairMove((param: any) => {
      const toolTipMargin = 15

      if (!param.time || param.point.x < 0 || param.point.y < 0) {
        toolTip.style.display = 'none'
        return
      }
      const dateStr = isBusinessDay(param.time)
        ? TradingviewService.businessDayToString(param.time)
        : new Date(param.time * 1000).toLocaleDateString()

      toolTip.style.display = 'block'
      const price = param.seriesPrices.get(candleSeries).close
      toolTip.innerHTML =
        '<div style="font-size: 17px; margin: 4px 0px; color: white">' + price + '</div>'

      const y = param.point.y

      const left = param.point.x + toolTipMargin
      const top = y + toolTipMargin

      toolTip.style.left = left + 'px'
      toolTip.style.top = top + 'px'
    })

    return {
      chart,
      trendlineSeries,
      color: data.trend_lines_color
    }
  }

  static swapTrendLinesCompareCharts (activeLines: boolean, chart: any) {
    if (activeLines) {
      chart.trendlineSeries.applyOptions({
        color: chart.color
      })
    } else {
      chart.trendlineSeries.applyOptions({
        color: 'transparent'
      })
    }
  }

  static businessDayToString (businessDay: any) {
    return businessDay.year + '-' + businessDay.month + '-' + businessDay.day
  }

  static createDiscoverChart (div: string) {
    const divChart: any = document.getElementById(div)
    divChart.innerHTML = ''
    const chart = createChart(divChart, {
      width: divChart.offsetWidth,
      height: 300,
      layout: {
        textColor: '#d1d4dc',
        backgroundColor: 'transparent'
      },
      leftPriceScale: {
        visible: false
      },
      rightPriceScale: {
        visible: false
      },
      handleScroll: false,
      handleScale: false,
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        },
        vertLine: {
          visible: true,
          style: 0,
          width: 2,
          color: 'rgba(32, 38, 46, 0.9)',
          labelVisible: true
        }
      },
      timeScale: {
        rightOffset: 0,
        barSpacing: 3,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        borderColor: '#fff000',
        visible: true,
        timeVisible: true,
        secondsVisible: false,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          return moment(`${time.year}-${time.month}-${time.day}`).format('MM/DD H:mm')
        }
      },
      localization: {
        locale: 'es-MX'
      },
      grid: {
        vertLines: {
          visible: false
        },
        horzLines: {
          visible: false
        }
      }
    })
    const lineSeries = chart.addLineSeries({
      color: '#5C6271',
      lineWidth: 3,
      crosshairMarkerVisible: false,
      lastValueVisible: false,
      priceLineVisible: false
    })
    lineSeries.setData([
      {
        time: '2019-04-11',
        value: 80.01
      },
      {
        time: '2019-04-12',
        value: 96.63
      },
      {
        time: '2019-04-13',
        value: 76.64
      },
      {
        time: '2019-04-14',
        value: 81.89
      },
      {
        time: '2019-04-15',
        value: 74.43
      },
      {
        time: '2019-04-16',
        value: 80.01
      },
      {
        time: '2019-04-17',
        value: 96.63
      },
      {
        time: '2019-04-18',
        value: 76.64
      },
      {
        time: '2019-04-19',
        value: 81.89
      },
      {
        time: '2019-04-20',
        value: 74.43
      }
    ])
    const lineSeries2 = chart.addLineSeries({
      color: '#19CEAA',
      lineWidth: 3,
      crosshairMarkerVisible: false,
      lastValueVisible: false,
      priceLineVisible: false
    })
    lineSeries2.setData([
      {
        time: '2019-04-11',
        value: 90.01
      },
      {
        time: '2019-04-12',
        value: 36.63
      },
      {
        time: '2019-04-13',
        value: 56.64
      },
      {
        time: '2019-04-14',
        value: 91.89
      },
      {
        time: '2019-04-15',
        value: 14.43
      },
      {
        time: '2019-04-16',
        value: 20.01
      },
      {
        time: '2019-04-17',
        value: 36.63
      },
      {
        time: '2019-04-18',
        value: 76.64
      },
      {
        time: '2019-04-19',
        value: 61.89
      },
      {
        time: '2019-04-20',
        value: 54.43
      }
    ])
    chart.timeScale().fitContent()
  }

  static createBasketChart (div: string, data: any, height = 100, tooltipConfig = { left: 0, top: 0, chartType: 'line' }) {
    const divChart: any = document.getElementById(div)
    divChart.innerHTML = ''
    const chart = createChart(divChart, {
      width: divChart.offsetWidth,
      height,
      layout: {
        textColor: '#d1d4dc',
        backgroundColor: 'transparent'
      },
      leftPriceScale: {
        visible: false
      },
      rightPriceScale: {
        visible: false
      },
      handleScroll: false,
      handleScale: false,
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        },
        vertLine: {
          visible: true,
          style: 0,
          width: 2,
          color: 'rgba(32, 38, 46, 0.9)',
          labelVisible: true
        }
      },
      timeScale: {
        rightOffset: 0,
        barSpacing: 3,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        borderColor: '#fff000',
        visible: true,
        timeVisible: true,
        secondsVisible: false,
        tickMarkFormatter: (time: any, tickMarkType: any, locale: any) => {
          return moment(time).format('D MMM YY H:mm')
        }
      },
      localization: {
        locale: 'es-Mx', // DYNAMIC
        timeFormatter: (time: any) => {
          return moment(time).format('D MMM YY H:mm')
        }
      },
      grid: {
        vertLines: {
          visible: false
        },
        horzLines: {
          visible: false
        }
      }
    })

    let lineSeries: any
    if (tooltipConfig.chartType === 'line') {
      lineSeries = chart.addLineSeries({
        color: '#19CEAA',
        lineWidth: 3,
        crosshairMarkerVisible: false,
        lastValueVisible: false,
        priceLineVisible: false
      })
    } else {
      lineSeries = chart.addAreaSeries({
        lineColor: '#6ef8d7',
        topColor: 'rgba(110, 248, 215, 0.20)',
        bottomColor: 'rgba(110, 248, 215, 0.05)',
        lineWidth: 2,
        crosshairMarkerVisible: true,
        lastValueVisible: false,
        priceLineVisible: false
      })
    }
    const dataFormatted = data.map((item: any) => {
      return {
        time: item.timestamp ? item.timestamp * 1000 : item.Datetime / 1000,
        value: item.price ? item.price : item.value,
        pct_change: item.pct_change ? item.pct_change : ''
      }
    })

    lineSeries.setData(dataFormatted)
    chart.timeScale().fitContent()

    const toolTip = document.createElement('div')
    toolTip.className = 'floating-tooltip-2'
    divChart.appendChild(toolTip)

    chart.subscribeCrosshairMove((param: any) => {
      const toolTipMargin = 15

      if (!param.time || param.point.x < 0 || param.point.y < 0) {
        toolTip.style.display = 'none'
        return
      }
      const dateStr = moment(param.time).format('D MMM YY H:mm')

      toolTip.style.display = 'flex'
      const pctChange = dataFormatted.find((el: any) => el.time === param.time).pct_change
      const pctDiv = pctChange > 0 ? `<span style="font-size: 15px; color: #20C997; text-align: center; margin-left: 40px; font-weight: bold;">${pctChange}%</span>` : `<span style="font-size: 15px; color: #D63384; text-align: center; margin-left: 40px; font-weight: bold;">${pctChange}%</span>`
      toolTip.innerHTML =
        `<div style="display: flex; justify-content: center; margin: 4px 0px; color: white">
          <div>
            <span style="font-size: 13px; color: white; text-align: center; font-weight: bold; margin-left: 12px">${dateStr}</span>
            ${pctDiv}
          </div>
        </div>`

      const y = param.point.y

      const left = param.point.x + toolTipMargin
      const top = y + toolTipMargin

      toolTip.style.left = left + tooltipConfig.left + 'px'
      toolTip.style.top = top + tooltipConfig.top + 'px'

      EventBus.$emit('pctHasChanged', pctChange)
    })
  }

  static createInvestChart (div: string) {
    const divChart: any = document.getElementById(div)
    divChart.innerHTML = ''
    const chart = createChart(divChart, {
      width: divChart.offsetWidth,
      height: 100,
      layout: {
        textColor: '#d1d4dc',
        backgroundColor: 'transparent'
      },
      leftPriceScale: {
        visible: false
      },
      rightPriceScale: {
        visible: false
      },
      handleScroll: false,
      handleScale: false,
      crosshair: {
        horzLine: {
          visible: false,
          labelVisible: false
        },
        vertLine: {
          visible: true,
          style: 0,
          width: 2,
          color: 'rgba(32, 38, 46, 0.9)',
          labelVisible: true
        }
      },
      timeScale: {
        rightOffset: 0,
        barSpacing: 3,
        fixLeftEdge: true,
        lockVisibleTimeRangeOnResize: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        borderColor: '#fff000',
        visible: true,
        timeVisible: true,
        secondsVisible: false
      },
      localization: {
        locale: 'es-MX'
      },
      grid: {
        vertLines: {
          visible: false
        },
        horzLines: {
          visible: false
        }
      }
    })
    const lineSeries2 = chart.addLineSeries({
      color: '#19CEAA',
      lineWidth: 3,
      crosshairMarkerVisible: false,
      lastValueVisible: false,
      priceLineVisible: false
    })
    lineSeries2.setData([
      {
        time: '2019-04-11',
        value: 90.01
      },
      {
        time: '2019-04-12',
        value: 36.63
      },
      {
        time: '2019-04-13',
        value: 56.64
      },
      {
        time: '2019-04-14',
        value: 91.89
      },
      {
        time: '2019-04-15',
        value: 14.43
      },
      {
        time: '2019-04-16',
        value: 20.01
      },
      {
        time: '2019-04-17',
        value: 36.63
      },
      {
        time: '2019-04-18',
        value: 76.64
      },
      {
        time: '2019-04-19',
        value: 61.89
      },
      {
        time: '2019-04-20',
        value: 54.43
      }
    ])
    chart.timeScale().fitContent()
  }
}
