export default {
  mutations: {
    UPDATE_CURRENT_SELECTED_SYMBOL: 'UPDATE_CURRENT_SELECTED_SYMBOL'
  },
  getters: {
    GET_DATA: 'GET_DATA'
  },
  actions: {
    UPDATE_CURRENT_SELECTED_SYMBOL: 'UPDATE_CURRENT_SELECTED_SYMBOL'
  }
}
