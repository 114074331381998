




































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import ReturnPage from '@/components/dashboard/ReturnPage.vue'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/plans'
import PlanService from '@/services/plans'
import UserTypes from '@/store/types/UserTypes'
import Utils from '@/helpers/Utils'

@Component({
  mixins: [validationMixin],
  validations: validations.validateCode,
  components: {
    ReturnPage: () => import('@/components/dashboard/ReturnPage.vue')
  }
})
export default class WithCodePlan extends Vue {
  code = ''
  email = ''
  showModal = false
  type = ''

  mounted () { this.validateUserPremium() }

  async validateCode () {
    this.loading(true)
    const [error] = await PlanService.validateCodePreOffer({ email: this.email, code: this.code })
    if (error) {
      this.loading(false)
      this.type = 'error'
      this.showModal = true
      return this.$toasted.global.error(error)
    }
    await this.updateDataUser()
    this.loading(false)
    this.type = 'success'
    this.showModal = true
  }

  async validateUserPremium () {
    if (this.$route.name !== 'WithCodePlan') return
    if (this.user.subscription_level !== 'free') return this.$router.push({ name: 'Home' })
    await Utils.sleep(3000)
    await this.updateDataUser()
    this.validateUserPremium()
  }

  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @Action(`UserModule/${UserTypes.actions.SET_DATA}`) updateDataUser: any;
  @State('UserModule') user: any;
}
