import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State from '@/store/interfaces/MenuLateral'
import MenuLateralTypes from '@/store/types/MenuLateralTypes'

const namespaced = true

const keyLocalStorage = 'menu-collapse'

const payloadLocalStorage: State = JSON.parse(localStorage.getItem(keyLocalStorage) || JSON.stringify({ collapse: 'expand', movil: false }))

const state: State = payloadLocalStorage

const getters: GetterTree<State, any> = {
  [MenuLateralTypes.getters.GET_DATA]: (state) => state
}

const mutations: MutationTree<State> = {
  [MenuLateralTypes.mutations.COLLAPSE_OR_UNCOLLAPSE]: async (stateMut, payload?: string) => {
    if (payload) state.collapse = payload
    else state.collapse = (state.collapse === 'expand') ? 'short' : 'expand'
    localStorage.setItem(keyLocalStorage, JSON.stringify(state))
  },
  [MenuLateralTypes.mutations.EXPAND_MENU]: async () => {
    state.collapse = 'expand'
    localStorage.setItem(keyLocalStorage, JSON.stringify(state))
  },
  [MenuLateralTypes.mutations.COLLAPSE_MOVIL]: async () => {
    state.movil = false
  },
  [MenuLateralTypes.mutations.SHOW_MENU_MOVIL]: async () => {
    state.movil = true
  }
}

const actions: ActionTree<State, any> = {
  [MenuLateralTypes.actions.COLLAPSE_OR_UNCOLLAPSE]: async ({ commit }, payload?: string) => {
    commit(MenuLateralTypes.mutations.COLLAPSE_OR_UNCOLLAPSE, payload)
    return [null]
  },
  [MenuLateralTypes.actions.COLLAPSE_MOVIL]: async ({ commit }) => {
    commit(MenuLateralTypes.mutations.COLLAPSE_MOVIL)
    return [null]
  },
  [MenuLateralTypes.actions.EXPAND_MENU]: async ({ commit }) => {
    commit(MenuLateralTypes.mutations.EXPAND_MENU)
    return [null]
  },
  [MenuLateralTypes.actions.COLLAPSE_MOVIL]: async ({ commit }) => {
    commit(MenuLateralTypes.mutations.COLLAPSE_MOVIL)
    return [null]
  },
  [MenuLateralTypes.actions.SHOW_MENU_MOVIL]: async ({ commit }) => {
    commit(MenuLateralTypes.mutations.SHOW_MENU_MOVIL)
    return [null]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
