import Axios from 'axios'
import router from '@/router/index'
import store from '@/store/index'
import AuthTypes from '@/store/types/AuthTypes'
// import UtilsTypes from '@/store/types/UtilsTypes'

const axios = Axios.create({})

axios.interceptors.response.use((config) => config,
  async (error) => {
    const originalRequest = error.config
    if (originalRequest.url.includes('user/refresh')) {
      await store.dispatch(`AuthModule/${AuthTypes.actions.LOGOUT}`)
      await store.dispatch('UPDATE_STATE_LOADING', false)
      return router.push({ name: 'Login' })
    } else if (error.response.status === 401 && error.response.data.msg && error.response.data.msg.includes('Token has expired')) {
      const [err, response] = await store.dispatch(`AuthModule/${AuthTypes.actions.REFRESH_TOKEN}`)
      if (err) return router.push({ name: 'Login' })
      const retryOriginalRequest = new Promise((resolve) => {
        originalRequest.headers.authorization = 'Bearer ' + response.access_token
        return resolve(axios(originalRequest))
      })
      return retryOriginalRequest
    }
    return Promise.reject(error)
  })

axios.interceptors.request.use((config: any) => {
  const payload = store.getters[`AuthModule/${AuthTypes.getters.GET_PAYLOAD}`]
  if (config.url.includes('user/refresh')) config.headers.authorization = `Bearer ${payload.refresh_token}`
  else if (payload.access_token !== '') config.headers.authorization = `Bearer ${payload.access_token}`
  return config
})

export default axios
