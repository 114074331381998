import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const db = firebase.initializeApp({
  apiKey: 'AIzaSyApCjWoyupQTAM0OinTXAy4tgNAJI3YD9I',
  authDomain: 'wrabbit-app.firebaseapp.com',
  databaseURL: 'https://wrabbit-app.firebaseio.com',
  projectId: 'wrabbit-app',
  storageBucket: 'wrabbit-app.appspot.com',
  messagingSenderId: '534040678247',
  appId: '1:534040678247:web:1b7ee9f03fa48960ef29c2',
  measurementId: 'G-GHGZQJKKQ5'
}).firestore()

export default db
