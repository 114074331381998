





































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import NavigationTypes from '@/store/types/NavigationTypes'
import moment from 'moment'
import StateUser from '@/store/interfaces/User'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/lead'
import UserService from '@/services/user'
import LeadService from '@/services/lead'
import numeral from 'numeral'
import { uniqBy } from 'lodash'

@Component({
  mixins: [validationMixin],
  validations: validations.create,
  components: {
    ReturnPage: () => import('@/components/dashboard/ReturnPage.vue')
  },
  filters: {
    currency (value: number) {
      return numeral(value).format('0,0.00')
    }
  }
})
export default class Score extends Vue {
  search = ''
  currency: any
  show = false
  moment = moment
  name = ''
  email = ''
  phone = ''
  filters: any = []
  filter_selected = ''
  product = 'wrabbit_standard'
  data = {
    month_conversion: 0,
    month_referrals: 0,
    total_earned: 0,
    historical_conversion: 0,
    month_earnings: 0,
    wrabbit_commision: 0,
    sales_pitch: '',
    demo_wrabbit: '',
    what_is_wrabbit: '',
    referrals_table: [],
    referrals_table_backup: [],
    wrabbit_pdf: '',
    blog_url: '',
    youtube_url: '',
    twitter_url: '',
    instagram_url: '',
    wrabbit_alpha_payment_link: '',
    wrabbit_standard_payment_link: '',
    share_link: ''
  }

  fields = [
    { key: 'email', label: 'Symbol', sortable: true },
    { key: 'username', label: 'Nombre', sortable: true, thClass: 'text-center' },
    { key: 'phone', label: 'Teléfono', sortable: true, thClass: 'text-center' },
    { key: 'created_on', label: 'Fecha de registro', sortable: true, thClass: 'text-center' },
    { key: 'status', label: 'Estatus', sortable: true, thClass: 'text-center' },
    { key: 'product', label: 'Plan', sortable: true, thClass: 'text-center' },
    { key: 'earnings', label: 'Comisión', sortable: true, thClass: 'text-center' }
  ]

  mounted () {
    this.setBreadcrums([{ text: 'lateralMenu.home', to: { name: 'Home' } }, { text: 'referral.my_score', active: true }])
    this.index()
  }

  async index () {
    this.loading(true)
    const [error, response] = await UserService.getMyScore()
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.data = response.data
    this.data.referrals_table_backup = response.data.referrals_table
    this.filters = uniqBy(this.data.referrals_table_backup, 'status')
  }

  async store () {
    this.loading(true)
    const [error] = await LeadService.create({
      name: this.name,
      email: this.email,
      phone: this.phone,
      product: this.product
    })
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.show = false
    this.index()
    this.$toasted.global.success({ message: 'Lead registrado 😌' })
  }

  searchAll () {
    this.data.referrals_table = this.data.referrals_table_backup.filter((data: any) => {
      return data.email.toLowerCase().includes(this.search.toLowerCase()) && data.status.toLowerCase().includes(this.filter_selected.toLowerCase())
    })
  }

  filterByStatus (status: string) {
    if (this.filter_selected === status) this.filter_selected = ''
    else this.filter_selected = status
    this.data.referrals_table = this.data.referrals_table_backup.filter((data: any) => {
      return data.status.toLowerCase().includes(this.filter_selected.toLowerCase()) && data.email.toLowerCase().includes(this.search.toLowerCase())
    })
  }

  copyText (sentence: string) {
    this.$copyText(sentence).then(() => {
      this.$toasted.global.success({ message: this.$t('alerts.copied') })
    }).catch((error: any) => this.$toasted.global.error(error))
  }

  goTo (url: string) {
    const element = document.createElement('a')
    element.href = url
    element.target = '_blank'
    element.click()
    element.remove()
  }

  @State('UserModule') user!: StateUser
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
}
