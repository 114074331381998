























import Utils from '@/helpers/Utils'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CustomizingOnboarding extends Vue {
  async mounted () {
    await Utils.sleep(2500)
    this.$router.push({ name: 'DoneOnboarding' })
  }
}
