
































































































































































































































































































import Collaborator from '@/services/collaborators'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import moment from 'moment'
import db from '@/firebase'
import { v4 as uuidv4 } from 'uuid'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/collaborators'
import { WebsocketClient } from 'binance'

@Component({
  mixins: [validationMixin],
  validations: validations.createTrade,
  components: { SliderHot: () => import('@/components/my-trades/SliderHotV2.vue') }
})
export default class FormTradingNights extends Vue {
  editTrade = false
  symbols: Array<any> = []
  moment = moment
  symbolsBackup: Array<any> = []
  symbolSelected = { symbol: '', logo: '' }
  currentTrade: any = { id: 0 }
  lastTrade: any = { id: 0 }
  search = ''
  showModalAlert = false
  currentStage = 1
  entry_price = ''
  take_profit_price = ''
  take_profit_pct = '0.00'
  stop_loss_price = ''
  stop_loss_pct = '0.00'
  leverage = 5
  position = 'short'
  signal_id = 0
  url_embed = ''
  url_share = ''
  wsKey = ''
  wsClient!: WebsocketClient;
  binanceDataPrice: any = null

  beforeDestroy () { this.closeWebSocket() }

  get profitLossPct () {
    return this.currentTrade.position === 'short'
      ? (((this.binanceDataPrice / this.currentTrade.entry_price - 1) * 100) * this.currentTrade.leverage) * -1
      : ((this.binanceDataPrice / this.currentTrade.entry_price - 1) * 100) * this.currentTrade.leverage
  }

  async mounted () {
    this.loading(true)
    await Promise.all([
      this.getSymbols(),
      this.getCurrentTrade(),
      this.getLastTrade()
    ])
    this.loading(false)
  }

  async getSymbols () {
    const [error, response] = await Collaborator.getSymbols()
    if (error) return this.$toasted.global.error(error)
    this.symbols = response.data
    this.symbolsBackup = response.data
  }

  initWebSocket () {
    this.closeWebSocket()
    this.wsClient = new WebsocketClient({ beautify: true })
    // notification when a connection is opened
    this.wsClient.on('open', (data: any) => { this.wsKey = data.wsKey })
    this.wsClient.on('formattedMessage', (data: any) => { this.binanceDataPrice = data.askPrice })
    this.wsClient.subscribeSymbolBookTicker(this.currentTrade.symbol.replace('/', ''), 'usdm')
    // wsClient.subscribeSpotSymbolMini24hrTicker(this.currentTrade.symbol.replace('/', ''))
  }

  closeWebSocket () { if (this.wsKey) this.wsClient.close(this.wsKey) }

  async getCurrentTrade () {
    const [error, response] = await Collaborator.getCurrentTrade()
    if (error) {
      this.currentTrade.id = 0
      if (error.response.data.code === 50) return
      return this.$toasted.global.error(error)
    }
    this.currentTrade = response.data
    this.currentStage = (this.currentTrade.current_stage === 'exit_completed') ? 3
      : (this.currentTrade.current_stage === 'entry_completed') ? 2 : 1
    await this.initWebSocket()
  }

  async getLastTrade () {
    const [error, response] = await Collaborator.getLastTrade()
    if (error) {
      this.currentTrade.id = 0
      return this.$toasted.global.error(error)
    }
    this.lastTrade = response.data
  }

  async create () {
    this.loading(true)
    await this.getCurrentTrade()
    if (this.currentTrade.id) {
      this.loading(false)
      return this.$toasted.global.error({ message: 'Se tiene que finalizar el trade actual antes de crear uno nuevo :)' })
    }
    const [error] = await Collaborator.create({
      symbol: this.symbolSelected.symbol,
      clock: '15m',
      entry_price: this.entry_price,
      take_profit_price: this.take_profit_price,
      take_profit_pct: (this.position === 'short') ? (Number(this.take_profit_pct) * -1).toString() : this.take_profit_pct,
      stop_loss_price: this.stop_loss_price,
      stop_loss_pct: (this.position === 'short') ? (Number(this.stop_loss_pct) * -1).toString() : this.stop_loss_pct,
      leverage: this.leverage,
      position: this.position
    })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    Promise.all([
      this.dispatchEvent(),
      this.getCurrentTrade(),
      this.getLastTrade()
    ])
    this.$toasted.global.success({ message: 'Trade creado' })
  }

  async nextStep () {
    this.loading(true)
    const [error] = await Collaborator.nextStage(this.currentTrade.id)
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.$toasted.global.success({ message: 'Se avanzo de etapa con éxito' })
    if (this.currentTrade.current_stage === 'entry_completed') {
      this.dispatchEventConfetti()
      this.closeWebSocket()
    }
    Promise.all([
      this.dispatchEvent(),
      this.getCurrentTrade(),
      this.getLastTrade()
    ])
    this.showModalAlert = false
  }

  prepareEditTrade () {
    this.editTrade = true
    this.symbolSelected.symbol = this.currentTrade.symbol
    this.symbolSelected.logo = this.currentTrade.symbol_icon_url
    window.scrollTo(0, 0)
    this.signal_id = this.currentTrade.id
    this.entry_price = this.currentTrade.entry_price
    this.take_profit_price = this.currentTrade.take_profit_price
    this.take_profit_pct = this.currentTrade.take_profit_pct
    this.stop_loss_price = this.currentTrade.stop_loss_price
    this.stop_loss_pct = this.currentTrade.stop_loss_pct
    this.leverage = this.currentTrade.leverage || 5
    this.position = this.currentTrade.position
    this.$toasted.global.success({ message: 'Información cargada' })
  }

  calculateProfit () {
    this.take_profit_pct = (this.position === 'short')
      ? ((Number(this.take_profit_price) / Number(this.entry_price) - 1) * 100 * -1).toFixed(2)
      : ((Number(this.take_profit_price) / Number(this.entry_price) - 1) * 100).toFixed(2)
  }

  calculateStopLoss () {
    this.stop_loss_pct = (this.position === 'short')
      ? ((Number(this.stop_loss_price) / Number(this.entry_price) - 1) * 100 * -1).toFixed(2)
      : ((Number(this.stop_loss_price) / Number(this.entry_price) - 1) * 100).toFixed(2)
  }

  async edit () {
    this.loading(true)
    const [error] = await Collaborator.editTrade({
      symbol: this.symbolSelected.symbol,
      entry_price: this.entry_price,
      take_profit_price: this.take_profit_price,
      take_profit_pct: (this.position === 'short') ? (Number(this.take_profit_pct) * -1) : this.take_profit_pct,
      stop_loss_price: this.stop_loss_price,
      stop_loss_pct: (this.position === 'short') ? (Number(this.stop_loss_pct) * -1) : this.stop_loss_pct,
      leverage: this.leverage
    })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    Promise.all([
      this.dispatchEvent(),
      this.getCurrentTrade(),
      this.getLastTrade()
    ])
    this.editTrade = false
    this.$toasted.global.success({ message: 'Trade editado' })
  }

  dispatchEvent () {
    db.collection('collaborators').doc('trading_nights').set({ id: uuidv4() })
  }

  dispatchEventConfetti () {
    db.collection('collaborators').doc('show_confetti').set({ id: uuidv4() })
  }

  async dispatchEventLinks () {
    this.loading(true)
    await db.collection('collaborators').doc('live').set({ url_embed: this.url_embed })
    this.loading(false)
  }

  searchSymbol () { this.symbols = this.symbolsBackup.filter((data: any) => data.symbol.toLowerCase().includes(this.search.toLowerCase())) }

  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
}
