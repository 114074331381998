










import UtilsTypes from '@/store/types/UtilsTypes'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
@Component({
  components: {
    Main: () => import('@/components/Main.vue'),
    LateralMenu: () => import('@/components/onboarding/LateralMenu.vue')
  }
})
export default class Welcome extends Vue {
  @State('UtilsModule') state: any;
  @State('UserModule') userState: any;
  @Action(`UtilsModule/${UtilsTypes.actions.SHOW_MODAL_TRADES}`) showModalTrades: any;
}
