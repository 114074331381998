import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State from '@/store/interfaces/User'
import UserTypes from '@/store/types/UserTypes'
import UserService from '@/services/user'

const namespaced = true

const state: State = {
  avatar_url: null,
  avatar_color: 'green',
  coupon_data: null,
  email: '',
  id: 0,
  is_active: false,
  is_mentor: false,
  is_confirmed: false,
  is_affiliate: false,
  notifications_enabled: false,
  language_settings: localStorage.getItem('lang') || 'es',
  rabbit_coins: 0,
  referral_category: '',
  referral_code: '',
  referral_details: { Annual: 0, Monthy: 0, Annual_Points: 0, Monthly_Points: 0 },
  start_guide_completed: false,
  subscription_category: null,
  subscription_current_payment_method: null,
  subscription_expires: '',
  subscription_level: '',
  investor_profile: null,
  trial_expires: 0,
  trading_enabled: false,
  telegram_notifications_enabled: false,
  ui_theme: '',
  telegram_username: '',
  username: '',
  country: '',
  metamask_address: '',
  carrots: 0,
  carrots_message: '',
  carrots_progress_bar: 0,
  tutorials: {},
  subscription_details: { is_subscribed: false, payment_method: null, payment_method_last4: null },
  medals_earned: {
    course: false,
    invest: false,
    onboarding: false,
    trade: false
  }
}

const getters: GetterTree<State, any> = {
  [UserTypes.getters.GET_PAYLOAD]: (state) => state
}

const mutations: MutationTree<State> = {
  [UserTypes.mutations.SET_DATA]: (stateMut, payload: State) => {
    // @ts-ignore
    for (const key in payload) state[key] = payload[key]
  },
  [UserTypes.mutations.CHANGE_ENABLED_TRADING]: (stateMut, payload: boolean) => {
    state.trading_enabled = payload
  }
}

const actions: ActionTree<State, any> = {
  [UserTypes.actions.SET_DATA]: async ({ commit }) => {
    const [err, response]: any = await UserService.getProfile()
    if (err) return [err]
    commit(UserTypes.mutations.SET_DATA, response.data)
    return [err, response.data]
  },
  [UserTypes.actions.CHANGE_ENABLED_TRADING]: async ({ commit }, data: boolean) => {
    commit(UserTypes.mutations.CHANGE_ENABLED_TRADING, data)
    return [null]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
