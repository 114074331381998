export default {
  mutations: {
    SET_COUNTRIES: 'SET_COUNTRIES',
    SET_LOCATION: 'SET_LOCATION'
  },
  getters: {
    GET_DATA: 'GET_DATA'
  },
  actions: {
    SET_COUNTRIES: 'SET_COUNTRIES',
    SET_LOCATION: 'SET_LOCATION'
  }
}
