import to from 'await-to-js'
import axiosA from 'axios'

const MORALIS_URL = 'https://deep-index.moralis.io/api/v2'
const API_KEY = process.env.VUE_APP_MORALIS_API_KEY

export default class WalletService {
  static async getNativeBalance (address: string, chain: string) {
    return await to(axiosA.get(
      `${MORALIS_URL}/${address}/balance`,
      { params: { chain }, headers: { 'X-API-Key': API_KEY } }
    )) as any
  }

  static async getTokensBalance (address: string, chain: string) {
    return await to(axiosA.get(
      `${MORALIS_URL}/${address}/erc20`,
      { params: { chain }, headers: { 'X-API-Key': API_KEY } }
    )) as any
  }

  static async getTokenMetadata (tokenAddress: string, chain: string) {
    return await to(axiosA.get(
      `${MORALIS_URL}/erc20/metadata`,
      { params: { chain, addresses: tokenAddress }, headers: { 'X-API-Key': API_KEY } }
    )) as any
  }
}
