












































































































































































































































import numeral from 'numeral'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import NavigationTypes from '@/store/types/NavigationTypes'
import InvestmentService from '@/services/investments'
import TradingviewService from '@/services/tradingview'
import moment from 'moment'
import EventBus from '@/event-bus'
import TrackEvents from '@/utils/track-events'

@Component({
  filters: {
    currency (value: number) {
      return numeral(value).format('0,0')
    }
  },
  components: {
    TableAssets: () => import('@/components/dashboard/home/TableAssets.vue'),
    TableFavoritesHome: () => import('@/components/dashboard/home/TableFavoritesHome.vue'),
    UserPortfolioPerformanceChart: () => import('./UserPortfolioPerformanceChart.vue'),
    MyInvestmentsTour: () => import('@/components/tours/MyInvestments.vue')
  }
})
export default class MyInvestments extends Vue {
  readyData = false
  investments = true
  userInvestments: any = []
  userTransactions: any = []
  moment = moment
  types = ['1w', '1m', '1y']
  typeSelected = '1w'
  noDataChart = false
  showPortfolioChart = false
  portfolioAllocations: any = false
  pctChange = 0
  userHasInvestments = true
  userHasInvestmentsButNoChart = false
  readyRenderTutorial = false

  mounted () {
    EventBus.$on('pctHasChanged', (pct: any) => {
      this.pctChange = pct
    })
    this.prepareData()
  }

  async prepareData () {
    TrackEvents.track('PV_Myinvestments')
    this.setBreadcrums([
      { text: 'lateralMenu.home', to: { name: 'Home' } },
      { text: 'lateralMenu.my-investments', active: true }
    ])
    this.loading(true)
    await Promise.all([this.getMyInvestments(), this.getMyTransactions(), this.getPorfolioPerformance(this.typeSelected), this.getUserPortfolioAllocations()])
    this.readyData = true
    this.loading(false)
  }

  showPortfolioChartFn () {
    this.showPortfolioChart = !this.showPortfolioChart
    this.typeSelected = ''
  }

  async getMyInvestments () {
    const [error, userInvestments] = await InvestmentService.getUserInvestments()
    if (error) return this.$toasted.global.error(error)
    this.userInvestments = userInvestments.data
  }

  async getMyTransactions () {
    const [error, userTransactions] = await InvestmentService.getUserTransactions()
    if (error) return this.$toasted.global.error(error)
    this.userTransactions = userTransactions.data
  }

  async getPorfolioPerformance (type: string) {
    this.loading(true)
    this.showPortfolioChart = false
    this.typeSelected = type
    const [error, portfolioPerformance] = await InvestmentService.getUserPortfolioPerformance(this.typeSelected)
    if (error) {
      if (error.response.data.message === 'user_has_no_investments') {
        this.userHasInvestments = false
        this.loading(false)
        this.readyRenderTutorial = true
        return
      }

      if (error.response.data.message === 'user_has_investments_but_no_chart') {
        this.noDataChart = true
        this.userHasInvestments = true
        this.userHasInvestmentsButNoChart = true
        this.loading(false)
        this.readyRenderTutorial = true
        return
      }
      this.noDataChart = true
      this.readyRenderTutorial = true
      return
    }
    this.userHasInvestments = true
    this.userHasInvestmentsButNoChart = false
    this.noDataChart = false
    this.readyRenderTutorial = true
    setTimeout(() => {
      TradingviewService.createBasketChart('portfolio-performance', portfolioPerformance.data.data, 400, { left: 0, top: 0, chartType: 'area' })
      this.loading(false)
    }, 1500)
  }

  capitalizeFirstLetter (string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  async getUserPortfolioAllocations () {
    const [error, portfolio] = await InvestmentService.getUserPortfolioAllocations()
    if (error) {
      if (error.response.data.message === 'user_has_no_investments') {
        this.userHasInvestments = false
        this.loading(false)
        return
      }

      if (error.response.data.message === 'user_has_investments_but_no_chart') {
        this.noDataChart = true
        this.userHasInvestments = true
        this.userHasInvestmentsButNoChart = true
        this.loading(false)
        return
      }
      this.noDataChart = true
      return
    }
    if (error) return this.$toasted.global.error(error)
    this.portfolioAllocations = portfolio.data
  }

  getUrlAvatar () {
    return require(`@/assets/images/presentation/step00_${this.user.avatar_color}.png`)
  }

  @State('ForecastModule') state: any
  @State('UtilsModule') utilState: any
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums: any
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark'
  @State('UserModule') user: any
}
