export default {
  mutations: {
    COLLAPSE_OR_UNCOLLAPSE: 'COLLAPSE_OR_UNCOLLAPSE',
    EXPAND_MENU: 'EXPAND_MENU',
    COLLAPSE_MOVIL: 'COLLAPSE_MOVIL',
    SHOW_MENU_MOVIL: 'SHOW_MENU_MOVIL'
  },
  getters: {
    GET_DATA: 'GET_DATA'
  },
  actions: {
    COLLAPSE_OR_UNCOLLAPSE: 'COLLAPSE_OR_UNCOLLAPSE',
    EXPAND_MENU: 'EXPAND_MENU',
    COLLAPSE_MOVIL: 'COLLAPSE_MOVIL',
    SHOW_MENU_MOVIL: 'SHOW_MENU_MOVIL'
  }
}
