export default {
  mutations: {
    SET_BREADCRUMS: 'SET_BREADCRUMS'
  },
  getters: {
    GET_PAYLOAD: 'GET_PAYLOAD',
    GET_BREADCRUMS: 'GET_BREADCRUMS'
  },
  actions: {
    SET_BREADCRUMS: 'SET_BREADCRUMS'
  }
}
