































































































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import NavigationTypes from '@/store/types/NavigationTypes'

@Component({
  components: { SelectSymbol: () => import('@/components/dashboard/home/start-guide/SelectSymbols.vue') }
})
export default class CustomFilters extends Vue {
  showModal = false

  mounted () {
    this.setBreadcrums([
      { text: 'lateralMenu.home', to: { name: 'Home' } },
      { text: 'lateralMenu.ask-wrabbit', to: { name: 'AskWrabbitCategory' } },
      { text: 'lateralMenu.market-custom-filter', active: true }])
  }

  @State('ForecastModule') state: any;
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
}
