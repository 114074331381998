import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'
import Score from '@/views/dashboard/plans/Score.vue'
// import AskWrabbitCategory from '@/views/dashboard/ask-wrabbit/Categories.vue'
import Markets from '@/router/markets'
// import Alerts from '@/router/alerts'
// import Backtesting from '@/router/backtesting'
import AskWrabbit from '@/router/ask-wrabbit'
import Tutorials from '@/views/dashboard/Tutorilas.vue'
import Baskets from '@/views/dashboard/my-investment/baskets/Baskets.vue'
import Mentors from '@/router/mentors'
import Learn from '@/router/learn'
import MyInvestment from '@/router/my-investments'
import Detail from '@/views/dashboard/mentors/Detail.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    component: () => import(/* webpackChunkName: "Index" */'@/views/dashboard/Index.vue'),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */'@/views/dashboard/Home.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Home' }
      },
      {
        path: 'score',
        name: 'Score',
        component: Score,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed])
      },
      {
        path: 'forecast/:symbol?',
        name: 'Forecast',
        component: () => import(/* webpackChunkName: "Forecast" */'@/views/dashboard/Forecast.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Forecast' }
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "Profile" */'@/views/dashboard/user-options/Profile.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
        meta: { title: 'Profile' }
      },
      // {
      //   path: 'ask-wrabbit',
      //   name: 'AskWrabbitCategory',
      //   component: AskWrabbitCategory,
      //   beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
      //   meta: { title: 'Ask wrabbit' }
      // },
      {
        path: 'tutorials',
        name: 'Tutorials',
        component: Tutorials,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
        meta: { title: 'Tutorials' }
      },
      {
        path: 'baskets',
        name: 'Baskets',
        component: Baskets,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Baskets' }
      },
      {
        path: 'market-analysis',
        name: 'MarketAnalysis',
        component: () => import(/* webpackChunkName: "MarketAnalysis" */'@/views/dashboard/ask-wrabbit/MarketAnalysis.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Market Analysis' }
      },
      {
        path: 'mentor/detail',
        name: 'MentorDetailMain',
        component: Detail,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
        meta: { title: 'Mentor Detail' }
      },
      ...Markets,
      // ...Alerts,
      // ...Backtesting,
      ...AskWrabbit,
      ...Mentors,
      ...Learn,
      ...MyInvestment
    ]
  }
]

export default routes
