



































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Action, State, Getter } from 'vuex-class'
import FavoriteService from '@/services/favorites'
import numeral from 'numeral'
import GroupService from '@/services/group'
import NavigationTypes from '@/store/types/NavigationTypes'
import AuthTypes from '@/store/types/AuthTypes'
import ForecastTypes from '@/store/types/ForecastTypes'
import EventBus from '@/event-bus'

@Component({
  components: {
    NotResults: () => import('@/components/NotResults.vue')
  },
  filters: { currency (value: number) { return numeral(value).format('0,0.00') } }
})
export default class MarketGroup extends Vue {
  stars = [1, 2, 3, 4, 5]
  search = ''
  readyData = false
  finishRequestForecast = false
  detailGroup = { name: '' }
  dataBackup: any = []
  tableData: any = {
    fields: [
      // { key: 'favorite', label: '', sortable: false, thStyle: 'width: 50px' },
      { key: 'symbol', label: 'Symbol', sortable: true, tdClass: 'symbol-column' },
      { key: 'last_price', label: 'Último precio', sortable: true, thClass: 'text-center' },
      { key: '24h_change', label: '24h Cambio', sortable: true, thClass: 'text-center' },
      { key: 'max_min_24', label: '24h Máx/Mín', sortable: false, thClass: 'text-center', thStyle: 'width: 250px' },
      { key: 'volatility', label: 'Volatilidad', sortable: true, thClass: 'text-center' },
      { key: 'volume', label: '24h Volumen (USDT)', sortable: true, thClass: 'text-center' },
      { key: 'trend', label: 'Tendencia', sortable: true, thClass: 'text-center' },
      { key: 'forecast_decision', label: 'Pronóstico', sortable: true, thClass: 'text-center' }
    ],
    items: []
  }

  async mounted () {
    EventBus.$on('update-data-custom-filter', () => { this.prepareData() })
    this.prepareData()
  }

  async prepareData () {
    this.loading(true)
    if (this.$route.params.filter === '0') {
      this.detailGroup = { name: 'main_menu.markets.favs' }
      await this.getForecastDefault()
    } else {
      this.detailGroup = { name: `main_menu.markets.${this.$route.params.filter}_title` }
      await this.getForecastByGroupId()
    }
    if (!this.breadcrums.find((breadcrum: any) => breadcrum.text === 'main_menu.markets.favs')) {
      await this.setBreadcrums([
        { text: 'lateralMenu.home', to: { name: 'Home' } },
        { text: 'lateralMenu.ask-wrabbit', to: { name: 'AskWrabbitCategory' } },
        { text: 'lateralMenu.market-custom-filter', to: { name: 'MarketCustomFilters' } },
        { text: this.detailGroup.name, active: true }])
    }
    this.loading(false)
  }

  async getForecastDefault () {
    this.tableData.items = []
    this.dataBackup = []
    const [error, response] = await GroupService.forecastDefault()
    this.finishRequestForecast = true
    if (error) return this.$toasted.global.error(error)
    for (const item of response.data) {
      if (this.state.favoritesFormatted[item.symbol]) {
        this.tableData.items.push({ ...this.state.favoritesFormatted[item.symbol], ...item })
        this.dataBackup.push({ ...this.state.favoritesFormatted[item.symbol], ...item })
      }
    }
  }

  async addOrRemoveFavorite (symbol: any) {
    this.loading(true)
    const [error] = await FavoriteService.addOrRemove({ symbol: symbol.symbol, action: (symbol.favorite) ? 'remove' : 'add' })
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: (symbol.favorite) ? this.$t('success_messages.delete_symbol') : this.$t('success_messages.add_symbol') })
    const [errorGetFavorites] = await this.setFavorites()
    if (errorGetFavorites) return this.$toasted.global.error(errorGetFavorites)
    await this.prepareData()
    this.searchSymbol()
  }

  searchSymbol () { this.tableData.items = this.dataBackup.filter((data: any) => data.symbol.toLowerCase().includes(this.search.toLowerCase())) }

  async getForecastByGroupId () {
    const [error, response] = await GroupService.forecastByGroupId(this.$route.params.filter)
    this.finishRequestForecast = true
    if (error) {
      return this.$toasted.global.error({ message: this.$t(`error_messages.${error.response.data.message}`) })
    }
    this.tableData.items = response.data
    this.dataBackup = response.data
  }

  async redirecWithNavigation (data: { symbol: string }) {
    await this.setBreadcrums([
      { text: 'lateralMenu.home', to: { name: 'Home' } },
      { text: 'lateralMenu.ask-wrabbit', to: { name: 'AskWrabbitCategory' } },
      { text: 'lateralMenu.market-custom-filter', to: { name: 'MarketCustomFilters' } },
      { text: this.detailGroup.name, to: { name: 'MarketGroup' } },
      { text: data.symbol, active: true }
    ])
    return this.$router.push({ name: 'MarketGroupForecast', params: { symbol: data.symbol.replace('/', '_') }, query: { clock: this.dataBackup[0].clock } })
  }

  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @State('ForecastModule') state: any;
  @Getter(`AuthModule/${AuthTypes.getters.GET_CURRENT_USER}`) user: any;
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
  @Action(`ForecastModule/${ForecastTypes.actions.UPDATE_DATA_FAVORITES}`) setFavorites: any;
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @Getter(`NavigationModule/${NavigationTypes.getters.GET_BREADCRUMS}`) breadcrums: any;
}
