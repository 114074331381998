import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State, { Country } from '@/store/interfaces/Location'
import LocationTypes from '@/store/types/LocationTypes'
import UserService from '@/services/user'
import StartGuideService from '@/services/startGuide'

const namespaced = true

const state: State = {
  countries: [],
  location: {
    alpha2code: '',
    alpha3code: '',
    code: '',
    country: '',
    currencies: { code: '', name: '', symbol: '' },
    flag: '',
    languages: { iso639_1: 'en', iso639_2: '', name: '', nativeName: '' },
    name: ''
  }
}

const getters: GetterTree<State, any> = {
  [LocationTypes.getters.GET_DATA]: (state) => state
}

const mutations: MutationTree<State> = {
  [LocationTypes.mutations.SET_COUNTRIES]: (stateMut, payload: Array<Country>) => {
    state.countries = payload
  },
  [LocationTypes.mutations.SET_LOCATION]: (stateMut, payload: Country) => {
    state.location = payload
  }
}

const actions: ActionTree<State, any> = {
  [LocationTypes.actions.SET_COUNTRIES]: async ({ commit }) => {
    const [error, response] = await StartGuideService.getCountryCodes()
    if (error) return [error]
    commit(LocationTypes.mutations.SET_COUNTRIES, response.data)
    return [error, response.data]
  },
  [LocationTypes.actions.SET_LOCATION]: async ({ commit }) => {
    const [err, response]: any = await UserService.getLocation()
    if (err) return [err]
    const findCountry = await state.countries.find(country => country.alpha2code === response.data.YourFuckingCountryCode)
    if (findCountry) await commit(LocationTypes.mutations.SET_LOCATION, findCountry)
    else return [{ message: 'No se encontro tu ubicacion' }]
    return [err, findCountry]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
