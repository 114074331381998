import Main from '@/components/Main.vue'
import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Auth from '@/middlewares/Auth'
import Detail from '@/views/dashboard/mentors/Detail.vue'
import User from '@/middlewares/User'
import Discover from '@/views/dashboard/Discover.vue'
import AllBaskets from '@/views/dashboard/discover/AllBaskets.vue'

const routes: Array<RouteConfig> = [
  {
    path: 'discover',
    component: Main,
    children: [
      {
        path: '',
        name: 'Discover',
        component: Discover,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
        meta: { title: 'Discover' }
      },
      {
        path: 'all-baskets',
        name: 'AllBaskets',
        component: AllBaskets,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
        meta: { title: 'All Baskets' }
      },
      {
        path: 'mentor/:id/detail',
        name: 'MentorDetail',
        component: Detail,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
        meta: { title: 'Mentor Detail | Discover' }
      }
    ]
  }
]
export default routes
