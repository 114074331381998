import axios from '@/utils/axios-api'
import to from 'await-to-js'

const PANNEL_URL = process.env.VUE_APP_API
const NEW_WRABBIT_URL = process.env.VUE_APP_NEW_WRABBIT_API
const MARKET_JOB_URL = process.env.VUE_APP_MARKET_JOB_API
const TRADING_BOT_URL = process.env.VUE_APP_TRADING_BOT_API

export default class NewWrabbitService {
  static async getTimezones () {
    return to(axios.get(`${PANNEL_URL}/timezones`)) as any
  }

  static async setInvertorProfile (data: {
    question_1: 'yes' | 'no' | null;
    question_2: 'stability' | 'car' | 'house' | 'business' | 'debt' | 'other' | null;
    question_3: 'long_term' | 'one_year' | 'three_months'| null;
    question_4: 'small_amount' | 'medium_amount' | 'big_amount'| null;
    question_5: 'angry' | 'understand' | 'calm' | 'invest'| null;
    main_goal: 'learn' | 'invest_popular_cryptos' | 'trading' | 'invest_moonshots' | 'protect_inflation'| null;
  }) {
    return to(axios.post(`${PANNEL_URL}/user/investor_profile`, data)) as any
  }

  static async getCommunityVoteBySymbol (symbol: string) {
    return to(axios.get(`${MARKET_JOB_URL}/community_vote/${symbol}`)) as any
  }

  static async getUserVoteBySymbol () {
    return to(axios.get(`${MARKET_JOB_URL}/user/community_vote_symbols`)) as any
  }

  static async vote (symbol: string, bullish: boolean) {
    return to(axios.post(`${MARKET_JOB_URL}/community_vote`, { symbol, bullish })) as any
  }

  static async connectToBinance (key: any, secret: any) {
    return to(axios.post(`${TRADING_BOT_URL}/user/create`, { key, secret })) as any
  }

  static async newWrabbitInterest () {
    return to(axios.post(`${PANNEL_URL}/new_wrabbit_interest`)) as any
  }

  static async createTransactionMetamask (data: { initial_value: number; basket_id: number; }) {
    return to(axios.post(`${NEW_WRABBIT_URL}/investment/create`, data)) as any
  }

  static async validateTransactionMetamask (data: { txhash: string; address: string; token_name: string; buy_transaction_id: number }) {
    return to(axios.post(`${NEW_WRABBIT_URL}/investment/validate_transaction`, data)) as any
  }

  static async getSwapWrabbitToken (params: { amount: number; matic_gas_fees: number; basket_id: number; side: string; }) {
    return to(axios.get(`${NEW_WRABBIT_URL}/investment/swap`, { params })) as any
  }
}
