

















































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import Utils from '@/helpers/Utils'
import InvestmentService from '@/services/investments'
import moment from 'moment'
import numeral from 'numeral'
import NavigationTypes from '@/store/types/NavigationTypes'

@Component({
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  }
})
export default class RecentActivity extends Vue {
  fields = [
    { key: 'moneda', label: this.$t('recent_activity.coin') },
    { key: 'accion', label: this.$t('recent_activity.action') },
    { key: 'cantidad', label: this.$t('recent_activity.amount') },
    { key: 'precio', label: this.$t('recent_activity.price') },
    { key: 'valor', label: this.$t('recent_activity.value') },
    { key: 'fecha', label: this.$t('recent_activity.date') }
  ]

  items= []

  rows= 7
  currentPage= 1
  perPage= 3
  search = ''
  userTransactions = []
  dataReady = false

  mounted () {
    this.setBreadcrums([
      { text: 'lateralMenu.home', to: { name: 'Home' } },
      { text: 'lateralMenu.my-investments', to: { name: 'MyInvestments' } },
      { text: 'lateralMenu.my-activity', active: true }
    ])
    this.getMyTransactions()
  }

  async celebrate () {
    // @ts-ignore
    this.$confetti.start({
      canvasId: 'custom-canvas',
      defaultDropRate: 2,
      defaultSize: 4,
      particlesPerFrame: 2
    })
    await Utils.sleep(2000)
    // @ts-ignore
    this.$confetti.stop()
  }

  async getMyTransactions () {
    function capitalizeFirstLetter (string: string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const [error, { data: userTransactions }] = await InvestmentService.getUserTransactions()
    this.items = userTransactions.map((el: any) => {
      return {
        moneda: capitalizeFirstLetter(el.coin_name),
        accion: el.transaction_type === 'buy' ? 'Compra' : 'Venta',
        value: el.transaction_type === 'buy' ? this.$t('main_menu.signals.buy') : this.$t('main_menu.signals.sell'),
        cantidad: el.qty,
        precio: el.price,
        valor: '$' + el.total,
        fecha: moment(el.created_on).format('LL'),
        logo_url: this.state.tickersFormatted[el.symbol_usdt].logo_url
      }
    })
    this.dataReady = true
  }

  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @State('ForecastModule') state: any;
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
}
