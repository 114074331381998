import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Main from '@/components/Main.vue'
import Auth from '@/middlewares/Auth'
import User from '@/middlewares/User'
import MyInvestments from '@/views/dashboard/my-investment/MyInvestments.vue'
import Baskets from '@/views/dashboard/my-investment/baskets/Baskets.vue'
import RecentActivity from '@/views/dashboard/my-investment/recent-activity/RecentActivity.vue'

const routes: Array<RouteConfig> = [
  {
    path: 'my-investments',
    component: Main,
    children: [
      {
        path: '',
        name: 'MyInvestments',
        component: MyInvestments,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'My Investments' }
      },
      {
        path: 'baskets',
        name: 'Baskets',
        component: Baskets,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Baskets' }
      },
      {
        path: 'recent-activity',
        name: 'RecentActivity',
        component: RecentActivity,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Recent Activity' }
      }
    ]
  }
]
export default routes
