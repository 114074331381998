import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import SelectTypePlan from '@/views/dashboard/plans/SelectTypePlan.vue'
import WithCode from '@/views/dashboard/plans/WithCode.vue'
import Auth from '@/middlewares/Auth'
import Main from '@/components/Main.vue'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: 'plans',
    component: Main,
    children: [
      {
        path: '',
        name: 'SelectTypePlan',
        component: SelectTypePlan,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Plans' }
      },
      {
        path: 'code',
        name: 'WithCodePlan',
        component: WithCode,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Plans | Code' }
      }
    ]
  }
]
export default routes
