import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State, { MetamaskData, BalanceDataBinance } from '@/store/interfaces/Wallet'
import WalletTypes from '@/store/types/WalletTypes'
import BinanceService from '@/services/binance'
import to from 'await-to-js'
import WalletService from '@/services/wallet'
import TrackEvents from '@/utils/track-events'
import store from '@/store/index'
import UserState from '@/store/interfaces/User'
import UserTypes from '@/store/types/UserTypes'
import UserService from '@/services/user'
import Web3 from 'web3'
import MetaMaskOnboarding from '@metamask/onboarding'

const ethNetwork = new Web3('https://cloudflare-eth.com')
const polygonNetwork = new Web3('https://polygon-rpc.com')

const namespaced = true

const keyLocalStorageDeletedConnection = 'deleted_connection_metamask'

const deleted = localStorage.getItem(keyLocalStorageDeletedConnection) === 'yes'

let onboarding: any = null

const state: State = {
  showWallets: false,
  metamaskData: {
    deleted,
    address: '',
    balances: {
      eth: { nativeBalance: 0, balanceTokens: [] },
      matic: { nativeBalance: 0, balanceTokens: [] }
    }
  },
  balanceBinance: {
    balance_usdt: 0,
    balance_usdt_futures: 0,
    warning: null,
    warning_futures: null
  }
}

const getters: GetterTree<State, any> = {
  [WalletTypes.getters.GET_DATA]: (state) => state,
  [WalletTypes.getters.GET_BINANCE_BALACE_DATA]: (state) => state.balanceBinance,
  [WalletTypes.getters.GET_METAMASK_DATA]: (state) => state.metamaskData
}

const mutations: MutationTree<State> = {
  [WalletTypes.mutations.CONNECT_TO_METAMASK]: async (stateMut, data: string) => {
    localStorage.removeItem(keyLocalStorageDeletedConnection)
    state.metamaskData.deleted = false
    state.metamaskData.address = data
  },
  [WalletTypes.mutations.SET_BALANCE_BINANCE]: async (stateMut, data: BalanceDataBinance) => {
    state.balanceBinance = data
  },
  [WalletTypes.mutations.SET_DATA_METAMASK]: async (stateMut, data: MetamaskData) => {
    state.metamaskData.address = data.address
    state.metamaskData.balances = data.balances
  },
  [WalletTypes.mutations.DELETE_METAMASK_CONNECTION]: async (stateMut) => {
    localStorage.setItem(keyLocalStorageDeletedConnection, 'yes')
    state.metamaskData.address = ''
    state.metamaskData.deleted = true
  }
}

const actions: ActionTree<State, any> = {
  [WalletTypes.actions.SET_BALANCE_BINANCE]: async ({ commit }) => {
    const [err, response]: any = await BinanceService.getBalanceUser()
    if (err) {
      state.balanceBinance = {
        balance_usdt: 0,
        balance_usdt_futures: 0,
        warning: null,
        warning_futures: null
      }
      store.dispatch(`UserModule/${UserTypes.actions.CHANGE_ENABLED_TRADING}`, false)
      return [err]
    }
    commit(WalletTypes.mutations.SET_BALANCE_BINANCE, response.data)
    return [err, response.data]
  },
  [WalletTypes.actions.CONNECT_TO_METAMASK]: async ({ commit }) => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      const ethereum = window.ethereum.providers ? window.ethereum.providers.find((provider: any) => provider.isMetaMask) : window.ethereum
      const [error, response] = await to(ethereum.request({ method: 'eth_requestAccounts' })) as any
      if (error) return [error]
      TrackEvents.track('AC_MetamaskConnected')
      const address = response[0]
      const user = store.getters[`UserModule/${UserTypes.getters.GET_PAYLOAD}`] as UserState
      if (user.metamask_address !== address) UserService.updateUser({ metamask_address: address })
      if (onboarding) onboarding.startOnboarding()
      commit(WalletTypes.mutations.CONNECT_TO_METAMASK, address)
      return [null]
    } else {
      if (onboarding === null) onboarding = new MetaMaskOnboarding()
      onboarding.startOnboarding()
      return [{ message: 'No se detecto Metamask, por favor agregar la extensión', code: 'NotFoundExtension' }]
    }
  },
  [WalletTypes.actions.CHANGE_POLYGON_NETWORK]: async ({ commit }) => {
    const ethereum = window.ethereum.providers ? window.ethereum.providers.find((provider: any) => provider.isMetaMask) : window.ethereum
    const chainId = await ethereum.request({ method: 'eth_chainId' })
    if (chainId === '0x89') return [null]
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x89' }]
      })
      return [null]
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x89',
                chainName: 'Polygon Mainnet',
                rpcUrls: ['https://polygon-rpc.com'],
                blockExplorerUrls: ['https://polygonscan.com'],
                nativeCurrency: {
                  name: 'MATIC',
                  symbol: 'MATIC',
                  decimals: 18
                }
              }
            ]
          })
          return [null]
        } catch (addError) {
          return [addError]
        }
      }
      return [null]
    }
  },
  [WalletTypes.actions.ADD_TOKEN_METAMASK]: async ({ commit }, data: { address: string; symbol: string; decimals: number; image: string; }) => {
    try {
      const ethereum = window.ethereum.providers ? window.ethereum.providers.find((provider: any) => provider.isMetaMask) : window.ethereum
      const wasAdded = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: data
        }
      })
      if (wasAdded) return [null, { messag: 'Token added' }]
      return [{ message: 'Error add token ' + data.symbol }]
    } catch (error) {
      return [error]
    }
  },
  [WalletTypes.actions.DELETE_METAMASK_CONNECTION]: async ({ commit }) => {
    commit(WalletTypes.mutations.DELETE_METAMASK_CONNECTION)
  },
  [WalletTypes.actions.SET_DATA_METAMASK]: async ({ commit }) => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      const ethereum = window.ethereum.providers ? window.ethereum.providers.find((provider: any) => provider.isMetaMask) : window.ethereum
      const [error, response] = await to(ethereum.request({ method: 'eth_accounts' })) as any
      if (error) {
        commit(WalletTypes.mutations.CONNECT_TO_METAMASK, '')
        return [error]
      }
      const address = response[0]
      if (!address) {
        commit(WalletTypes.mutations.CONNECT_TO_METAMASK, '')
        return [{ message: 'ErrorGetAddress' }]
      }
      commit(WalletTypes.mutations.CONNECT_TO_METAMASK, address)

      const [errorGetBalances, responses] = await to(Promise.all([
        ethNetwork.eth.getBalance(address),
        WalletService.getTokensBalance(address, 'eth'),
        polygonNetwork.eth.getBalance(address),
        WalletService.getTokensBalance(address, 'matic')
      ])) as any
      if (errorGetBalances) {
        return [errorGetBalances]
      }
      const responseEthTokenBalance = responses[1][1].data
      const eth = { nativeBalance: await ethNetwork.utils.fromWei(responses[0]), balanceTokens: responseEthTokenBalance }
      const responseMaticTokenBalance = responses[3][1].data
      const matic = { nativeBalance: await ethNetwork.utils.fromWei(responses[2]), balanceTokens: responseMaticTokenBalance }
      commit(WalletTypes.mutations.SET_DATA_METAMASK, { address, balances: { eth, matic } })
      return [null]
    } else return [{ message: 'No se detecto extensión Metamask', code: 'NotFoundExtension' }]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
