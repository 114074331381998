import to from 'await-to-js'
import axios from '@/utils/axios-api'

const API_NEW_WRABBIT_URL = process.env.VUE_APP_NEW_WRABBIT_API

export default class BasketsService {
  static async getUserBaskets () {
    return await to(axios.get(`${API_NEW_WRABBIT_URL}/basket/user/my_baskets`)) as any
  }

  static async getBaskets (riskLevel: string) {
    return await to(axios.get(`${API_NEW_WRABBIT_URL}/basket_filter?risk_level=${riskLevel}`)) as any
  }

  static async getBasket (id: string) {
    return await to(axios.get(`${API_NEW_WRABBIT_URL}/basket/${id}`)) as any
  }

  static async getBasketPerformance (id: string, timeframe = '1w') {
    return await to(axios.get(`${API_NEW_WRABBIT_URL}/basket/portfolio_performance/${id}?clock=${timeframe}`)) as any
  }

  static async getBasketWrabbitSelection () {
    return await to(axios.get(`${API_NEW_WRABBIT_URL}/basket_wrabbit_selection`)) as any
  }

  static async addBasketToFavorites (id: string) {
    return await to(axios.post(`${API_NEW_WRABBIT_URL}/user/basket/modify`, {
      basket_id: id,
      action: 'add',
      category: 'favorites'
    })) as any
  }

  static async removeBasketToFavorites (id: string) {
    return await to(axios.post(`${API_NEW_WRABBIT_URL}/user/basket/modify`, {
      basket_id: id,
      action: 'remove',
      category: 'favorites'
    })) as any
  }

  static async addBasketToLikes (id: string) {
    return await to(axios.post(`${API_NEW_WRABBIT_URL}/user/basket/modify`, {
      basket_id: id,
      action: 'add',
      category: 'likes'
    })) as any
  }

  static async removeBasketToLikes (id: string) {
    return await to(axios.post(`${API_NEW_WRABBIT_URL}/user/basket/modify`, {
      basket_id: id,
      action: 'remove',
      category: 'likes'
    })) as any
  }

  static async investBasket (data: { basket_id: number; initial_value: number; }) {
    return await to(axios.post(`${API_NEW_WRABBIT_URL}/investment/create`, data)) as any
  }
}
