























import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import NavigationTypes from '@/store/types/NavigationTypes'
import UserService from '@/services/user'
import TrackEvents from '@/utils/track-events'

@Component
export default class Tutorials extends Vue {
  showModal = false
  tutorials: any = []

  mounted () {
    TrackEvents.track('PV_Tutorials')
    this.setBreadcrums([{ text: 'lateralMenu.home', to: { name: 'Home' } }, { text: 'lateralMenu.tutorials', active: true }])
    this.getTutorials()
  }

  async getTutorials () {
    const [error, response] = await UserService.getTutorials()
    if (error) return this.$toasted.global.error(error)
    this.tutorials = response.data
  }

  redirectTo (url: string) {
    const element = document.createElement('a')
    element.href = url
    element.target = '_blank'
    element.click()
    element.remove()
  }

  @State('ForecastModule') state: any;
  @Action(`NavigationModule/${NavigationTypes.actions.SET_BREADCRUMS}`) setBreadcrums!: (state: Array<any>) => void
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
}
