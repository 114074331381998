export default {
  mutations: {
    CRONS_RUNNING: 'CRONS_RUNNING',
    SHOW_MODAL_TRADES: 'SHOW_MODAL_TRADES',
    SHOW_MODAL_ERROR_500: 'SHOW_MODAL_ERROR_500',
    SHOW_GLOBAL_SEARCH: 'SHOW_GLOBAL_SEARCH',
    STEP_PRESENTATION: 'STEP_PRESENTATION',
    MODE_SANDBOX: 'MODE_SANDBOX',
    AVATAR_SELECTED: 'AVATAR_SELECTED',
    CHANGE_STEP_ONBOARDING_TOUR: 'CHANGE_STEP_ONBOARDING_TOUR',
    USERNAME_SELECTED: 'USERNAME_SELECTED',
    CLEAR_DATA: 'CLEAR_DATA'
  },
  getters: {
    GET_DATA: 'GET_DATA'
  },
  actions: {
    CRONS_RUNNING: 'CRONS_RUNNING',
    SHOW_MODAL_TRADES: 'SHOW_MODAL_TRADES',
    SHOW_MODAL_ERROR_500: 'SHOW_MODAL_ERROR_500',
    SHOW_GLOBAL_SEARCH: 'SHOW_GLOBAL_SEARCH',
    STEP_PRESENTATION: 'STEP_PRESENTATION',
    AVATAR_SELECTED: 'AVATAR_SELECTED',
    MODE_SANDBOX: 'MODE_SANDBOX',
    USERNAME_SELECTED: 'USERNAME_SELECTED',
    CHANGE_STEP_ONBOARDING_TOUR: 'CHANGE_STEP_ONBOARDING_TOUR',
    CLEAR_DATA: 'CLEAR_DATA'
  }
}
