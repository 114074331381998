import to from 'await-to-js'
import axios from '@/utils/axios-api'
import axiosA from 'axios'

const PANEL_URL = process.env.VUE_APP_API

export default class UserService {
  static async getTutorials () {
    return await to(axios.get(`${PANEL_URL}/tutorials/all`)) as any
  }

  static async getSubDetail () {
    return await to(axios.get(`${PANEL_URL}/user/subscription_details`)) as any
  }

  static async changePassword (password: string) {
    return await to(axios.post(`${PANEL_URL}/user/change_password`, { password })) as any
  }

  static async register (email: string, password: string, referred_by: any) {
    return await to(axios.post(`${PANEL_URL}/user/register`, { email, password, register_source: 'web_version', referred_by, avatar_color: 'green' })) as any
  }

  static async referralCode (referral_code: string) {
    return await to(axios.post(`${PANEL_URL}/referral_code/validate`, { referral_code })) as any
  }

  static async getProfile () {
    return await to(axios.get(`${PANEL_URL}/user`)) as any
  }

  static async getTelegramConfigurationStatus () {
    return await to(axios.post(`${PANEL_URL}/validate_telegram_configuration`)) as any
  }

  static async updateUser (data: {
    notifications_enabled?: boolean;
    language_settings?: string;
    username?: string;
    avatar_color?: string;
    timezone?: string;
    first_trade_tutorial?: boolean;
    telegram_username?: string;
    telegram_notifications_enabled?: boolean;
    country?: string;
    referred_by?: string;
    metamask_address?: string;
  }) {
    return await to(axios.put(`${PANEL_URL}/user`, data)) as any
  }

  static async disabledSubscription (data: { feedback: any; comments: any; }) {
    return await to(axios.post(`${PANEL_URL}/stripe_cancel_subscription`, data)) as any
  }

  static async getMyScore () {
    return await to(axios.get(`${PANEL_URL}/user/referral_program`)) as any
  }

  static async getColorsAvatar () {
    return await to(axios.get(`${PANEL_URL}/avatar_colors`)) as any
  }

  static async saveAvatar (color: string, category: string) {
    return await to(axios.post(`${PANEL_URL}/avatar_update`, {
      color,
      category
    })) as any
  }

  static async checkUsernameAvailability (username: string) {
    return await to(axios.get(`${PANEL_URL}/user/available/${username}`)) as any
  }

  static async checkReferralCodeAvailability (code: string) {
    return await to(axios.post(`${PANEL_URL}/user/validate_referral`, {
      referred_by: code
    })) as any
  }

  static async getLocation () {
    return await to(axiosA.get('https://wtfismyip.com/json')) as any
  }

  static async updateTutorial (data: any) {
    return await to(axios.put(`${PANEL_URL}/user/modify_tutorials`, data)) as any
  }
}
