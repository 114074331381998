


































































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import { Action, State } from 'vuex-class'
import PlanService from '@/services/plans'

@Component({
  components: {
    Header: () => import('@/components/auth/Header.vue')
  }
})
export default class BlackFriday extends Vue {
  hours = 0
  mins = 0
  seconds = 0

  mounted () {
    const endSpecialOffer = moment().endOf('day').add(6, 'hours')
    const startTimestamp = moment.duration(endSpecialOffer.diff(moment()))
    this.hours = startTimestamp.hours()
    this.mins = startTimestamp.minutes()
    this.seconds = startTimestamp.seconds()
    setInterval(() => {
      const endSpecialOffer = moment().endOf('day').add(6, 'hours')
      const startTimestamp = moment.duration(endSpecialOffer.diff(moment()))
      this.hours = startTimestamp.hours()
      this.mins = startTimestamp.minutes()
      this.seconds = startTimestamp.seconds()
    }, 1000)
  }

  async redirectToStripe () {
    if (!this.stateUser.id) return this.$router.push({ name: 'Login' })
    this.loading(true)
    const [error, response] = await PlanService.redirectToStripe('Crypto Addict Yearly', 'black_friday')
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    window.location = response.data.data
  }

  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @State('UserModule') stateUser: any;
}
