import store from '@/store'
import UtilsTypes from '@/store/types/UtilsTypes'

export default class Utils {
  static async sleep (ms = 1000) {
    return new Promise((resolve) => {
      const interval = setTimeout(() => {
        clearTimeout(interval)
        return resolve('')
      }, ms)
    })
  }

  static isUrlWithPaper () {
    return store.getters[`UtilsModule/${UtilsTypes.getters.GET_DATA}`].modeSandbox ? 'paper_' : ''
  }
}
