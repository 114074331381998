import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Auth from '@/middlewares/Auth'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "IndexLogin" */'@/views/auth/Index.vue'),
    children: [
      {
        path: '',
        redirect: '/login'
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */'@/views/auth/Login.vue'),
        beforeEnter: multiguard([Auth.validateToken])
      }
    ]
  }
]

export default routes
