import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State from '@/store/interfaces/Profiler'
import ProfilerTypes from '@/store/types/ProfilerTypes'

const namespaced = true

const payloadLocalStorage: State = { currentPage: 1, numberQuestions: 0 }

const state: State = payloadLocalStorage

const getters: GetterTree<State, any> = {
  [ProfilerTypes.getters.GET_DATA]: (state) => state
}

const mutations: MutationTree<State> = {
  [ProfilerTypes.mutations.RESET]: async () => {
    state.currentPage = 1
  },
  [ProfilerTypes.mutations.NUMBER_QUESTIONS]: async (state, payload: number) => {
    state.numberQuestions = payload
  },
  [ProfilerTypes.mutations.NEXT_PAGE]: async () => {
    state.currentPage++
  },
  [ProfilerTypes.mutations.LAST_PAGE]: async () => {
    state.currentPage--
  }
}

const actions: ActionTree<State, any> = {
  [ProfilerTypes.actions.RESET]: async ({ commit }) => {
    commit(ProfilerTypes.mutations.RESET)
    return [null]
  },
  [ProfilerTypes.actions.NUMBER_QUESTIONS]: async ({ commit }, payload: number) => {
    commit(ProfilerTypes.mutations.NUMBER_QUESTIONS, payload)
    return [null]
  },
  [ProfilerTypes.actions.NEXT_PAGE]: async ({ commit }) => {
    commit(ProfilerTypes.mutations.NEXT_PAGE)
    return [null]
  },
  [ProfilerTypes.actions.LAST_PAGE]: async ({ commit }) => {
    commit(ProfilerTypes.mutations.LAST_PAGE)
    return [null]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
