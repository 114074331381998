import axios from '@/utils/axios-api'
import to from 'await-to-js'

const PANEL_URL = process.env.VUE_APP_API

export default class StartGuideService {
  static async getStartGuide () {
    return to(axios.get(`${PANEL_URL}/user/start_guide`)) as any
  }

  static async getProfilingQuestions () {
    return to(axios.get(`${PANEL_URL}/profiling_questions/all`)) as any
  }

  static async storeFavorites (data: { symbol_1: string; symbol_2: string; symbol_3: string; }) {
    return to(axios.post(`${PANEL_URL}/user/start_guide/favorites`, data)) as any
  }

  static async storeQuestions (data: any) {
    return to(axios.post(`${PANEL_URL}/user/profiling_questions`, data)) as any
  }

  static async updateStatus (data: { event_id?: number; is_complete?: boolean; value?: any; country?: string; country_code?: string; phone?: string; }) {
    return to(axios.post(`${PANEL_URL}/user/start_guide`, data)) as any
  }

  static async getFavoritesMock () {
    return to(axios.get(`${PANEL_URL}/onboarding_favorite_markets`)) as any
  }

  static async getCountryCodes () {
    return to(axios.get(`${PANEL_URL}/country_codes`)) as any
  }
}
