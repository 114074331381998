export default {
  mutations: {
    UPDATE_DATA_SUMMARY: 'UPDATE_DATA_SUMMARY',
    UPDATE_DATA_TICKERS: 'UPDATE_DATA_TICKERS',
    UPDATE_DATA_FAVORITES: 'UPDATE_DATA_FAVORITES',
    UPDATE_DATA_MARKETS: 'UPDATE_DATA_MARKETS'
  },
  getters: {
    GET_DATA: 'GET_DATA',
    GET_DATA_SUMMARY: 'GET_DATA_SUMMARY',
    GET_BTC_USDT: 'GET_BTC_USDT',
    GET_FAVORITES: 'GET_FAVORITES'
  },
  actions: {
    UPDATE_DATA_SUMMARY: 'UPDATE_DATA_SUMMARY',
    UPDATE_DATA_MARKETS: 'UPDATE_DATA_MARKETS',
    UPDATE_DATA_TICKERS: 'UPDATE_DATA_TICKERS',
    UPDATE_DATA_FAVORITES: 'UPDATE_DATA_FAVORITES'
  }
}
