import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Auth from '@/router/auth'
import Signup from '@/router/signup'
import Dashboard from '@/router/dashboard'
import DashboardFullPage from '@/router/dashboard_full_page'
import PageNotFound from '@/views/errors/PageNotFound.vue'
// import PaymentApproved from '@/views/success/PaymentApproved.vue'
import TradingNights from '@/views/collaborators/TradingNights.vue'
import FormTradingNights from '@/views/collaborators/FormTradingNights.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  ...Auth,
  ...Signup,
  ...Dashboard,
  ...DashboardFullPage,
  // {
  //   path: '/checkout_success',
  //   name: 'PaymentApproved',
  //   component: PaymentApproved
  // },
  {
    path: '/trading-nights',
    name: 'TradingNights',
    component: TradingNights
  },
  {
    path: '/trading-nights/form',
    name: 'FormTradingNights',
    component: FormTradingNights
  },
  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = (to.meta.title) ? to.meta.title + ' | Wrabbit ' : 'Wrabbit'
  next()
})

export default router
