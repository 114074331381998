import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from '@/store/modules/AuthModule'
import ProfilerModule from '@/store/modules/ProfilerModule'
import MenuLateralModule from '@/store/modules/MenuLateralModule'
import ForecastModule from '@/store/modules/ForecastModule'
import SymbolModule from '@/store/modules/SymbolModule'
import NavigationModule from '@/store/modules/NavigationModule'
import UserModule from '@/store/modules/UserModule'
import UtilsModule from '@/store/modules/UtilsModule'
import WalletModule from '@/store/modules/WalletModule'
import LocationModule from '@/store/modules/LocationModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: { loading: false, theme: localStorage.getItem('theme') || 'dark' },
  getters: {
    CURRENT_THEME: (state) => state.theme
  },
  mutations: {
    START_LOADING: (state) => { state.loading = true },
    FINISH_LOADING: (state) => { state.loading = false },
    UPDATE_THEME: (state, payload) => {
      state.theme = payload
      localStorage.setItem('theme', payload)
    }
  },
  actions: {
    UPDATE_STATE_LOADING: ({ commit }, status) => {
      if (status) commit('START_LOADING')
      else commit('FINISH_LOADING')
    },
    THEME: ({ commit }, payload) => {
      commit('UPDATE_THEME', payload)
    }
  },
  modules: {
    AuthModule,
    ProfilerModule,
    MenuLateralModule,
    ForecastModule,
    SymbolModule,
    NavigationModule,
    UserModule,
    UtilsModule,
    WalletModule,
    LocationModule
  }
})
