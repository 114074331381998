




































import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class StartOnboarding extends Vue {
  getUrlAvatar () {
    return require(`@/assets/images/presentation/${this.state.avatarSelected}.png`)
  }

  @State('UtilsModule') state: any;
}
