import to from 'await-to-js'
import axios from '@/utils/axios-api'

const PANEL_URL = process.env.VUE_APP_TRADING_NIGHTS_API

export default class Collaborator {
  static async getSymbols () {
    return await to(axios.get(`${PANEL_URL}/symbols`)) as any
  }

  static async getCurrentTrade () {
    return await to(axios.get(`${PANEL_URL}/current_signal`)) as any
  }

  static async getLastTrade () {
    return await to(axios.get(`${PANEL_URL}/last_trade`)) as any
  }

  static async create (data: {
    symbol: string,
    clock: string,
    entry_price: string,
    take_profit_price: string,
    take_profit_pct: string,
    stop_loss_price: string,
    stop_loss_pct: string,
    leverage: number,
    position: string
  }) {
    return await to(axios.post(`${PANEL_URL}/trading_signal/create`, data)) as any
  }

  static async editTrade (data: any) {
    return await to(axios.post(`${PANEL_URL}/edit_trading_signal`, data)) as any
  }

  static async nextStage (signalId: number) {
    return await to(axios.post(`${PANEL_URL}/update_trade_stage`, { signal_id: signalId })) as any
  }

  static async getRecentTrades () {
    return await to(axios.get(`${PANEL_URL}/recent_trades`)) as any
  }

  static async createSchedule (data: { country: string; country_code: string, phone: string, email: string, name: string }) {
    return await to(axios.post(`${PANEL_URL}/waitlist`, data)) as any
  }
}
