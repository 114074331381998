




















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Utils from '@/helpers/Utils'
import { WebsocketClient } from 'binance'
import Collaborator from '@/services/collaborators'
import db from '@/firebase'
import EventBus from '@/event-bus'
import moment from 'moment'
moment.locale('es')

@Component({
  components: {
    SliderHot: () => import('@/components/my-trades/SliderHotV2.vue'),
    ModalScheduleCall: () => import('@/components/dashboard/home/ModalScheduleCall.vue')
  }
})
export default class TradingNights extends Vue {
  moment = moment
  items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  recentTrades: Array<any> = []
  currentTrade: any = { id: 0 }
  lastTrade: any = { id: 0 }
  binanceDataPrice: any = null
  currentStage = 1
  url_embed = ''
  url_share = ''
  wsKey = ''
  wsClient!: WebsocketClient;
  eventsFirestore: any

  beforeDestroy () {
    if (this.eventsFirestore) this.eventsFirestore()
    this.closeWebSocket()
  }

  get profitLossPct () {
    return this.currentTrade.position === 'short'
      ? (((this.binanceDataPrice / this.currentTrade.entry_price - 1) * 100) * this.currentTrade.leverage) * -1
      : ((this.binanceDataPrice / this.currentTrade.entry_price - 1) * 100) * this.currentTrade.leverage
  }

  async mounted () {
    this.loading(true)
    await Promise.all([
      this.getLastTrade(),
      this.getRecentTrades(),
      this.onEventTradingNights(),
      this.getCurrentTrade()
    ])
    this.loading(false)
  }

  async getCurrentTrade () {
    const [error, response] = await Collaborator.getCurrentTrade()
    if (error) {
      this.currentTrade.id = 0
      if (error.response.data.code === 50) return
      return this.$toasted.global.error(error)
    }
    this.currentTrade = response.data
    this.currentStage = (this.currentTrade.current_stage === 'exit_completed') ? 3
      : (this.currentTrade.current_stage === 'entry_completed') ? 2 : 1
    await this.initWebSocket()
  }

  initWebSocket () {
    this.closeWebSocket()
    this.wsClient = new WebsocketClient({ beautify: true })
    this.wsClient.on('open', (data: any) => { this.wsKey = data.wsKey })
    this.wsClient.on('formattedMessage', (data: any) => { this.binanceDataPrice = data.askPrice })
    this.wsClient.subscribeSymbolBookTicker(this.currentTrade.symbol.replace('/', ''), 'usdm')
  }

  closeWebSocket () { if (this.wsKey) this.wsClient.close(this.wsKey) }

  async getLastTrade () {
    const [error, response] = await Collaborator.getLastTrade()
    if (error) {
      this.lastTrade.id = 0
      return this.$toasted.global.error(error)
    }
    this.lastTrade = response.data
  }

  async getRecentTrades () {
    const [error, response] = await Collaborator.getRecentTrades()
    if (error) return this.$toasted.global.error(error)
    this.recentTrades = response.data
  }

  copyText (value: string) {
    this.$copyText(value).then(() => {
      this.$toasted.global.success({ message: 'Copiado' })
    }).catch((error: any) => this.$toasted.global.error(error))
  }

  goToBinance () {
    const element = document.createElement('a')
    element.href = `https://www.binance.com/en/futures/${this.currentTrade.symbol.replace('/', '')}`
    element.target = '_blank'
    element.click()
    element.remove()
  }

  getUrlAvatar (step: string) {
    return require(`@/assets/images/presentation/${step}_green.png`)
  }

  async actionConfetti () {
    // @ts-ignore
    this.$confetti.start({
      defaultDropRate: 9,
      defaultSize: 18,
      particlesPerFrame: 1,
      particles: [
        {
          type: 'image',
          url: 'https://firebasestorage.googleapis.com/v0/b/wrabbit-app.appspot.com/o/emojis%2Ffire.png?alt=media&token=bd40a4fe-e767-470b-a88b-d16c5124a8af'
        },
        {
          type: 'image',
          url: 'https://firebasestorage.googleapis.com/v0/b/wrabbit-app.appspot.com/o/emojis%2Fmoney-face.png?alt=media&token=59d65145-87d2-490b-8336-5ebe13839963'
        },
        {
          type: 'image',
          url: 'https://firebasestorage.googleapis.com/v0/b/wrabbit-app.appspot.com/o/emojis%2Fmoney-bag.png?alt=media&token=6a5e9369-dbf0-41e5-bf05-4317c94cc03c'
        },
        {
          type: 'image',
          url: 'https://firebasestorage.googleapis.com/v0/b/wrabbit-app.appspot.com/o/emojis%2Frock.png?alt=media&token=32678791-3740-4562-b2e8-2937c904c254'
        },
        {
          type: 'image',
          url: 'https://firebasestorage.googleapis.com/v0/b/wrabbit-app.appspot.com/o/emojis%2Fmoney_wings.png?alt=media&token=d96b0188-34e2-43a6-bfa3-db00f1e89485'
        }
      ]
    })
    await Utils.sleep(4000)
    // @ts-ignore
    this.$confetti.stop()
  }

  openModalSchedule () {
    EventBus.$emit('open-modal-schedule')
  }

  onEventTradingNights () {
    this.eventsFirestore = db.collection('collaborators').onSnapshot(ref => {
      ref.docChanges().map(async (change) => {
        if (change.type === 'modified' && change.doc.id === 'trading_nights') {
          this.loading(true)
          await Promise.all([
            this.getCurrentTrade(),
            this.getLastTrade(),
            this.getRecentTrades()
          ])
          this.loading(false)
        }
        if (change.type === 'modified' && change.doc.id === 'show_confetti') {
          this.closeWebSocket()
          this.actionConfetti()
        }
        if (change.doc.id === 'live') {
          this.url_embed = change.doc.data().url_embed
          this.url_share = change.doc.data().url_embed
        }
      })
    })
  }

  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
}
