import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Auth from '@/middlewares/Auth'
import Welcome from '@/views/dashboard/welcome/Welcome.vue'
import InitOnboarding from '@/views/dashboard/welcome/InitOnboarding.vue'
import QuizOnboarding from '@/views/dashboard/welcome/QuizOnboarding.vue'
import InformationOnboarding from '@/views/dashboard/welcome/InformationOnboarding.vue'
import CustomizingOnboarding from '@/views/dashboard/welcome/CustomizingOnboarding.vue'
import MeetOnboarding from '@/views/dashboard/welcome/MeetOnboarding.vue'
import DoneOnboarding from '@/views/dashboard/welcome/DoneOnboarding.vue'
import StartOnboarding from '@/views/dashboard/welcome/StartOnboarding.vue'
import ChooseCountry from '@/views/dashboard/welcome/ChooseCountry.vue'
import ChooseAvatar from '@/views/dashboard/welcome/ChooseAvatar.vue'
import User from '@/middlewares/User'

const routes: Array<RouteConfig> = [
  {
    path: 'welcome',
    component: Welcome,
    children: [
      {
        path: '',
        name: 'StartOnboarding',
        component: StartOnboarding,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Start' }
      },

      {
        path: 'choose-avatar',
        name: 'ChooseAvatarOnboarding',
        component: ChooseAvatar,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Information' }
      },
      {
        path: 'choose-country',
        name: 'OnboardingCountry',
        component: ChooseCountry,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Country' }
      },
      {
        path: 'timezone',
        name: 'OnboardingInformation',
        component: InformationOnboarding,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Information' }
      },
      {
        path: 'start',
        name: 'WelcomeOnboarding',
        component: InitOnboarding,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Welcome' }
      },
      {
        path: 'quiz',
        name: 'OnboardingQuiz',
        component: QuizOnboarding,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Quiz' }
      },
      {
        path: 'customizing',
        name: 'OnboardingCustomizing',
        component: CustomizingOnboarding,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Customizing' }
      },
      {
        path: 'meet',
        name: 'OnboardingMeet',
        component: MeetOnboarding,
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Meet' }
      },
      {
        path: 'done',
        component: DoneOnboarding,
        name: 'DoneOnboarding',
        beforeEnter: multiguard([Auth.isLogged, User.profile]),
        meta: { title: 'Done' }
      }
    ]
  }
]

export default routes
