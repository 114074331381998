import { required, email } from 'vuelidate/lib/validators'

const validations = {
  create: {
    name: { required },
    email: { required, email },
    phone: { required },
    product: { required }
  }
}

export default validations
