import to from 'await-to-js'
import axios from '@/utils/axios-api'

const API_URL = process.env.VUE_APP_NEW_WRABBIT_API

export default class MentorsService {
  static async getMentor (id: string) {
    return await to(axios.get(`${API_URL}/mentor/${id}`)) as any
  }

  static async getMentorMain () {
    return await to(axios.get(`${API_URL}/mentor_profile`)) as any
  }
}
