export default {
  mutations: {
    RESET: 'RESET',
    NUMBER_QUESTIONS: 'NUMBER_QUESTIONS',
    NEXT_PAGE: 'NEXT_PAGE',
    LAST_PAGE: 'LAST_PAGE'
  },
  getters: {
    GET_DATA: 'GET_DATA'
  },
  actions: {
    RESET: 'RESET',
    NUMBER_QUESTIONS: 'NUMBER_QUESTIONS',
    NEXT_PAGE: 'NEXT_PAGE',
    LAST_PAGE: 'LAST_PAGE'
  }
}
