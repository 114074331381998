import store from '@/store/index'
import State from '@/store/interfaces/User'
import { MetamaskData } from '@/store/interfaces/Wallet'
import UserTypes from '@/store/types/UserTypes'
import WalletTypes from '@/store/types/WalletTypes'
import To from 'await-to-js'

export default class Auth {
  static async profile (to: any, from: any, next: any) {
    const user = store.getters[`UserModule/${UserTypes.getters.GET_PAYLOAD}`] as State
    if (user.id) {
      return next()
    } else {
      const [error] = await To(store.dispatch(`UserModule/${UserTypes.actions.SET_DATA}`))
      if (error) return next({ name: 'Login' })
      else return next()
    }
  }

  static async isSubscribed (to: any, from: any, next: any) {
    const user = store.getters[`UserModule/${UserTypes.getters.GET_PAYLOAD}`] as State
    if (user.subscription_level !== 'free') return next()
    else return next({ name: 'SelectTypePlan' })
  }

  static async isMentor (to: any, from: any, next: any) {
    const user = store.getters[`UserModule/${UserTypes.getters.GET_PAYLOAD}`] as State
    if (user.is_mentor) return next()
    else return next({ name: 'Home' })
  }

  static async isWrabbitAlpha (to: any, from: any, next: any) {
    const user = store.getters[`UserModule/${UserTypes.getters.GET_PAYLOAD}`] as State
    if (user.subscription_level === 'wrabbit_alpha') return next()
    else return next({ name: 'Home' })
  }

  static async setBalanceBinance (to: any, from: any, next: any) {
    const user = store.getters[`UserModule/${UserTypes.getters.GET_PAYLOAD}`] as State
    if (user.trading_enabled) store.dispatch(`WalletModule/${WalletTypes.actions.SET_BALANCE_BINANCE}`)
    return next()
  }

  static async setMetamaskData (to: any, from: any, next: any) {
    const metamask = store.getters[`WalletModule/${WalletTypes.getters.GET_METAMASK_DATA}`] as MetamaskData
    if (metamask.deleted) {
      next()
    } else {
      store.dispatch(`WalletModule/${WalletTypes.actions.SET_DATA_METAMASK}`)
      return next()
    }
  }
}
