





















































































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/onboarding'
import NewWrabbitService from '@/services/newWrabbit'
import UserService from '@/services/user'
import StateUser from '@/store/interfaces/User'
import UserTypes from '@/store/types/UserTypes'
import debounce from 'lodash/debounce'

@Component({
  mixins: [validationMixin],
  validations: validations.information
})
export default class InformationOnboarding extends Vue {
  codes: Array<{ timezone: string; country: string; flag: string; }> = []
  codesBackup: Array<{ timezone: string; country: string; flag: string; }> = []
  timezone = 'America/Mexico_City'
  country = 'Mexico'
  flag = 'https://flagcdn.com/w320/mx.png'
  username = ''
  referred_by: any = ''
  search = ''
  countryCode: any = ''
  referralCodeAvailability = true

  mounted () {
    this.countryCode = this.$route.query.country
    this.getCountryCodes()
    this.username = this.user.username
    if (localStorage.getItem('referred_by')) this.referred_by = localStorage.getItem('referred_by')
  }

  checkReferralCodeAvailability = debounce(this.validateCode, 500)

  async validateCode (code: string) {
    if (code === '') {
      this.referralCodeAvailability = true
      return
    }
    this.updateLoading(true)
    const [error, data] = await UserService.checkReferralCodeAvailability(code)
    this.updateLoading(false)
    if (error) {
      this.referralCodeAvailability = false
      return
    }
    this.referralCodeAvailability = true
  }

  async getCountryCodes () {
    this.codes = []
    this.codesBackup = []
    this.loading(true)
    const [error, response] = await NewWrabbitService.getTimezones()
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    for (const country in response.data) {
      for (const timezone of response.data[country].timezones) {
        this.codes.push({
          country: country,
          flag: response.data[country].flag,
          timezone
        })
        this.codesBackup.push({
          country: country,
          flag: response.data[country].flag,
          timezone
        })
      }
    }

    const code: any = this.codes.find(code => code.country.toLowerCase() === this.countryCode)
    this.flag = code.flag
    this.timezone = code.timezone
    this.country = code.country
  }

  async update () {
    this.loading(true)
    const [error] = await UserService.updateUser({ timezone: this.timezone, username: this.username, country: this.country.toLowerCase(), referred_by: this.referred_by })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.updateUser()
    this.$router.push({ name: 'WelcomeOnboarding' })
  }

  searchCountry () { this.codes = this.codesBackup.filter(data => data.timezone.toLowerCase().includes(this.search.toLowerCase())) }

  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void;
  @Action(`UserModule/${UserTypes.actions.SET_DATA}`) updateUser: any;
  @State('UserModule') user!: StateUser;
  @State('UtilsModule') state: any;
  @Action('UPDATE_STATE_LOADING') updateLoading: any
}
