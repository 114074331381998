import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State, { Breadcrums } from '@/store/interfaces/Navigation'
import NavigationTypes from '@/store/types/NavigationTypes'

const namespaced = true

const keyLocalStorage = 'navigation'

const state: State = JSON.parse(localStorage.getItem(keyLocalStorage) || JSON.stringify({ breadcrums: [] }))

function updateLocalStorage () { localStorage.setItem(keyLocalStorage, JSON.stringify(state)) }

const getters: GetterTree<State, any> = {
  [NavigationTypes.getters.GET_PAYLOAD]: (state) => state,
  [NavigationTypes.getters.GET_BREADCRUMS]: (state) => state.breadcrums
}

const mutations: MutationTree<State> = {
  [NavigationTypes.mutations.SET_BREADCRUMS]: async (stateMut, payload: Array<Breadcrums>) => {
    state.breadcrums = payload
    updateLocalStorage()
  }
}

const actions: ActionTree<State, any> = {
  [NavigationTypes.actions.SET_BREADCRUMS]: async ({ commit }, payload: Array<Breadcrums>) => {
    commit(NavigationTypes.mutations.SET_BREADCRUMS, payload)
    return [null, payload]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
