












































import { Component, Vue } from 'vue-property-decorator'
import { Getter, State } from 'vuex-class'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/onboarding'

@Component({
  mixins: [validationMixin],
  validations: validations.information
})
export default class ChooseCountry extends Vue {
  codes: Array<{ timezone: string; country: string; flag: string; }> = []
  codesBackup: Array<{ timezone: string; country: string; flag: string; }> = []
  timezone = 'America/Mexico_City'
  country = 'Mexico'
  flag = 'https://flagcdn.com/w320/mx.png'
  username = ''
  search = ''
  responses: any = {
    a2: 'mexico'
  }

  questions = [
    {
      emoji: '',
      question: this.$t('presentation.countries.MX'),
      value: 'mexico'
    },
    {
      emoji: '',
      question: this.$t('presentation.countries.ARG'),
      value: 'argentina'
    },
    {
      emoji: '',
      question: this.$t('presentation.countries.COL'),
      value: 'colombia'
    },
    {
      emoji: '',
      question: this.$t('presentation.countries.PER'),
      value: 'peru'
    },
    {
      emoji: '',
      question: this.$t('presentation.countries.CHI'),
      value: 'chile'
    },
    {
      emoji: '',
      question: this.$t('presentation.countries.OTHER'),
      value: 'other'
    }
  ]

  @Getter('CURRENT_THEME') theme!: 'light' | 'dark';
  @State('UtilsModule') state: any;
}
