import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Main from '@/components/Main.vue'
import Auth from '@/middlewares/Auth'
import QuickScanPresentation from '@/views/dashboard/ask-wrabbit/QuickScanPresentation.vue'
import Onboarding from '@/router/onboarding'
import TradingNights from '@/router/trading-nights'
import User from '@/middlewares/User'
import BlackFriday from '@/views/offers/BlackFriday.vue'
import Plans from '@/router/plans'

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    component: Main,
    children: [
      {
        path: 'trade-ideas',
        name: 'TradeIdeas',
        component: () => import(/* webpackChunkName: "TradeIdeas" */'@/views/dashboard/TradeIdeas.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
        meta: { title: 'Trade ideas' }
      },
      {
        path: 'ask-wrabbit/quick-scan/:symbol/presentation',
        name: 'QuickScanPresentation',
        component: QuickScanPresentation,
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
        meta: { title: 'Presentation | Quick scan' }
      },
      {
        path: 'market/overview',
        name: 'MarketOverview',
        component: () => import(/* webpackChunkName: "MarketOverview" */'@/views/dashboard/markets/MarketOverview.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed])
      },
      {
        path: 'forecast/compare/symbols',
        name: 'CompareSymbols',
        component: () => import(/* webpackChunkName: "CompareSymbols" */'@/views/dashboard/forecast/CompareSymbols.vue'),
        beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData])
      },
      {
        path: 'discover/basket/:id',
        component: Main,
        children: [
          {
            path: '',
            name: 'DiscoverDetailBasket',
            component: () => import(/* webpackChunkName: "DiscoverDetailBasket" */'@/views/dashboard/discover/DiscoverDetailBasket.vue'),
            beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData])
          },
          {
            path: 'congrats-investment',
            name: 'CongratsInvestment',
            component: () => import(/* webpackChunkName: "CongratsInvestment" */'@/views/dashboard/my-investment/CongratsInvest.vue'),
            beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed, User.setBalanceBinance, User.setMetamaskData]),
            meta: { title: 'Congrats Investment.ts' }
          }
          // {
          //   path: 'congrats-investment-nft',
          //   name: 'CongratsInvestmentNft',
          //   component: () => import(/* webpackChunkName: "CongratsInvestment" */'@/views/dashboard/my-investment/CongratsInvest.vue'),
          //   beforeEnter: multiguard([Auth.isLogged, User.profile, User.isSubscribed]),
          //   meta: { title: 'Congrats Investment.ts' }
          // }
        ]
      },
      ...Plans,
      ...Onboarding,
      ...TradingNights
    ]
  },
  {
    path: '/blackfriday',
    component: BlackFriday
  }
]

export default routes
