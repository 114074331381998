import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State, { Summary, Symbols as SymbolA, Ticker, Market } from '@/store/interfaces/Forecast'
import ForecastTypes from '@/store/types/ForecastTypes'
import ForecastService from '../../services/forecast'
import { Summary as SummaryF } from '@/interfaces/Forecast'
import db from '@/firebase'
import Utils from '@/helpers/Utils'

const namespaced = true

const state: State = {
  summary: {
    clock: '',
    entry_point: null,
    forecast: {},
    horizontal_lines: {},
    last_updated: '',
    pivot_value: 0,
    price_type: '',
    stop_loss: null,
    symbol: '',
    take_profit: null,
    technical_analysis: { table: [], slider: {} },
    trend_lines: { trend_lines: [] },
    wrabbit_indicators: []
  },
  markets: [],
  marketsFormatted: {},
  tickers: [],
  tickersFormatted: {},
  tickersFormattedOld: {},
  favorites: [],
  favoritesFormatted: [],
  multiclock: {
    multiclock_rabbit_indicators: [],
    multiclock_ta_indicators: [],
    price_type: '',
    symbol: ''
  }
}

const getters: GetterTree<State, any> = {
  [ForecastTypes.getters.GET_DATA]: (state) => state,
  [ForecastTypes.getters.GET_DATA_SUMMARY]: (state) => state.summary
}

const mutations: MutationTree<State> = {
  [ForecastTypes.mutations.UPDATE_DATA_SUMMARY]: (stateMut, payload: Summary) => {
    state.summary = payload
  },
  [ForecastTypes.mutations.UPDATE_DATA_MARKETS]: (stateMut, payload: Array<Market>) => {
    state.marketsFormatted = {}
    for (const symbol of payload) state.marketsFormatted[symbol.symbol] = symbol
    state.markets = payload
  },
  [ForecastTypes.mutations.UPDATE_DATA_TICKERS]: async (stateMut, payload: Array<Ticker>) => {
    const withData = Object.keys(state.tickersFormatted).length
    if (withData) state.tickersFormattedOld = state.tickersFormatted
    else state.tickersFormattedOld = {}
    await Utils.sleep(20)
    state.tickersFormatted = {}
    for (const ticker of payload) {
      const max_min_24 = (ticker.last_price - ticker.low) / (ticker.high - ticker.low) * 100
      const data = { ...ticker, max_min_24 }
      state.tickersFormatted[ticker.symbol] = data
      if (!withData) state.tickersFormattedOld[ticker.symbol] = data
    }
    state.tickers = payload
  },
  [ForecastTypes.mutations.UPDATE_DATA_FAVORITES]: (stateMut, payload: Array<SymbolA>) => {
    state.favoritesFormatted = {}
    for (const favorite of payload) state.favoritesFormatted[favorite.symbol] = favorite
    state.favorites = payload
  }
}

const actions: ActionTree<State, any> = {
  [ForecastTypes.actions.UPDATE_DATA_SUMMARY]: async ({ commit }, payload: SummaryF) => {
    const forecast = await db.collection('forecasts').doc(payload.symbol.replace('/', '_')).collection(payload.clock).doc('forecast').get()
    const data: any = forecast.data()
    commit(
      ForecastTypes.mutations.UPDATE_DATA_SUMMARY,
      {
        ...data.data,
        forecast: JSON.parse(data.data.forecast),
        horizontal_lines: JSON.parse(data.data.horizontal_lines),
        trend_lines: JSON.parse(data.data.trend_lines),
        secondary_chart_1: JSON.parse(data.data.secondary_chart_1),
        secondary_chart_2: JSON.parse(data.data.secondary_chart_2)
      }
    )
    return [null]
  },
  [ForecastTypes.actions.UPDATE_DATA_MARKETS]: async ({ commit }) => {
    await db.collection('summary_table').doc('info').onSnapshot(ref => {
      const doc: any = ref.data()
      commit(ForecastTypes.mutations.UPDATE_DATA_MARKETS, typeof doc.data === 'string' ? JSON.parse(doc.data) : doc.data)
    })
    return [null]
  },
  [ForecastTypes.actions.UPDATE_DATA_TICKERS]: async ({ commit }) => {
    await db.collection('ticker_all').doc('info').onSnapshot(async ref => {
      const doc: any = ref.data()
      commit(ForecastTypes.mutations.UPDATE_DATA_TICKERS, typeof doc.data === 'string' ? JSON.parse(doc.data) : doc.data)
    })
    return [null]
  },
  [ForecastTypes.actions.UPDATE_DATA_FAVORITES]: async ({ commit }) => {
    const [err, response] = await ForecastService.getFavorites()
    if (err) return [err]
    commit(ForecastTypes.mutations.UPDATE_DATA_FAVORITES, response.data)
    return [null]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
