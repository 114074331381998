




















































































import UserTypes from '@/store/types/UserTypes'
import UtilsTypes from '@/store/types/UtilsTypes'
import { Component, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import StateUser from '@/store/interfaces/User'

@Component
export default class DoneOnboarding extends Vue {
  created () { this.updateUserData() }

  getUrlAvatar () {
    return require(`@/assets/images/presentation/step00_${this.user.avatar_color}.png`)
  }

  completeOnboarding () {
    this.$router.push({ name: 'Home' })
  }

  @Action('UPDATE_STATE_LOADING') loading!: (state: boolean) => void
  @Action(`UserModule/${UserTypes.actions.SET_DATA}`) updateUserData: any;
  @Action(`UtilsModule/${UtilsTypes.actions.CHANGE_STEP_ONBOARDING_TOUR}`) updateStepOnboarding: any
  @State('UserModule') user!: StateUser;
}
