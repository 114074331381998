import { GetterTree, ActionTree, MutationTree } from 'vuex'
import State from '@/store/interfaces/Auth'
import AuthTypes from '@/store/types/AuthTypes'
import AuthService from '@/services/auth'
import axios from '@/utils/axios-api'
import Login from '@/interfaces/Login'
import GoogleLogin from '@/interfaces/GoogleLogin'
import jwtDecode from 'jwt-decode'

const namespaced = true

const payloadLocalStorage = JSON.parse(localStorage.payload || JSON.stringify({ access_token: '', refresh_token: '', user: {} }))

const state: State = {
  access_token: payloadLocalStorage.access_token,
  refresh_token: payloadLocalStorage.refresh_token,
  user: payloadLocalStorage.user
}

const getters: GetterTree<State, any> = {
  [AuthTypes.getters.GET_PAYLOAD]: (state) => state,
  [AuthTypes.getters.GET_CURRENT_USER]: (state) => state.user
}

const mutations: MutationTree<State> = {
  [AuthTypes.mutations.SET_PAYLOAD]: async (stateMut, payload) => {
    axios.interceptors.request.use(async (config) => {
      config.headers.authorization = `Bearer ${payload.access_token}`
      return config
    })
    state.access_token = payload.access_token
    if (payload.refresh_token) state.refresh_token = payload.refresh_token
    state.user = jwtDecode(payload.access_token)
    localStorage.setItem('payload', JSON.stringify(state))
  },
  [AuthTypes.mutations.REMOVE_TOKEN]: async () => {
    state.access_token = ''
    state.refresh_token = ''
    state.user = {}
    localStorage.removeItem('payload')
  }
}

const actions: ActionTree<State, any> = {
  [AuthTypes.actions.LOGIN]: async ({ commit }, login: Login) => {
    const [err, response]: any = await AuthService.login(login)
    if (err) return [err]
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  },
  [AuthTypes.actions.GOOGLE_LOGIN]: async ({ commit }, googleUser: GoogleLogin) => {
    const [err, response]: any = await AuthService.googleLogin(googleUser)
    if (err) return [err]
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  },
  [AuthTypes.actions.LOGOUT]: ({ commit }) => commit(AuthTypes.mutations.REMOVE_TOKEN),
  [AuthTypes.actions.REFRESH_TOKEN]: async ({ commit }) => {
    const [err, response] = await AuthService.refreshToken()
    if (err) return [err]
    commit(AuthTypes.mutations.SET_PAYLOAD, response.data)
    return [null, response.data]
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
